import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SideBar from '../SideBar/SideBar';
import Header from '../Header/Header';
import { fetchAndStoreMultipleDevicesTodayData } from '../redux/action/totalData';
import Loading from '../common/Loading';
import store from '../redux';
import { loadUnitSnapshot } from '../redux/action/dailyUnit';
import { selectedHouse as selectedHouseFromDropdown, selectedRoom as selectedRoomFromDropdown, selectedRoomDevices, selectedDeviceFromDropdown } from '../redux/action/deviceDropdownSelection';
import MainV2 from './MainV2';
import { selectedDay } from '../redux/action/selectedDate';
import { housesListAction } from '../redux/action/housesList';
import { selectedHouse } from '../redux/actionTypes';
import { config } from '../../config';
export const styles = {
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
  },
  rightContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
};
function MainPage() {
  const { selectedDeviceDetails: { deviceId: selectedDeviceId } = {}, roomId, devices } = useSelector(state => state.dropdownSelectionData);
  const houseData = useSelector(state => state.houseData);

  const date = useSelector(state => state.selectedDate);

  const [loading, setLoading] = useState(false);
  const [dateA, setDateA] = useState(new Date());
  const [roomCount, setRoomCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(houseData).length === 0) return;
      store.dispatch(selectedHouseFromDropdown(houseData._id))

      // const roomsWithNonEmptyEnergySync = houseData?.rooms?.filter(room => room.energiSync.length > 0);
      const roomsWithNonEmptyEnergySync = houseData?.rooms?.filter(room => room?.energiSync.length > 0 || room?.weatherSensors.length>0 || room.thermoSmarts.length>0 || room.environSyncs.length>0);

      if (roomsWithNonEmptyEnergySync.length === 0) return;
      setRoomCount(roomsWithNonEmptyEnergySync.length)
      const defaultRoom = roomsWithNonEmptyEnergySync[0]._id;
      const defaultRoomAllDevices = [...roomsWithNonEmptyEnergySync[0].energiSync.map((e)=>{
        return{...e,"type":config.deviceType.energiSync}
      }),...roomsWithNonEmptyEnergySync[0].thermoSmarts.map((e)=>{
        return{...e,"type":config.deviceType.thermoSmarts}
      }),...roomsWithNonEmptyEnergySync[0].weatherSensors.map((e)=>{
        return{...e,"type":config.deviceType.weatherSensors}
      }),...roomsWithNonEmptyEnergySync[0].environSyncs.map((e)=>{
        return{...e,"type":config.deviceType.environSyncs}
      })]
      const defaultDeviceDetails = defaultRoomAllDevices[0];

      console.log(houseData)

      store.dispatch(selectedRoomFromDropdown(defaultRoom));
      store.dispatch(selectedRoomDevices(defaultRoomAllDevices));
      store.dispatch(selectedDeviceFromDropdown(defaultDeviceDetails));


      console.log(date,'date')
  
    };

    fetchData();

    // Cleanup function
    return () => {
      // Add any necessary cleanup logic here if needed in the future
    };
  }, [houseData]);

  return (
    <div style={{ ...styles.container }}>
      {/* <SideBar /> */}
      <div style={{ ...styles.rightContainer }}>
        <Header />
        <MainV2 setLoading={setLoading} dateA={dateA} setDateA={setDateA} setRoomCount={setRoomCount} roomCount={roomCount} />
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default MainPage;
