
import React, { useEffect, useState } from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';
import { messages } from '../common/notification';
import { deviceControl, deviceDetails } from '../common/ApiCall';

function ACStatusDetailContainer({ time, selectedDeviceSelectedDayData, selectedDate, deviceId }) {
  const [deviceDetail, setDeviceDetails] = useState({});

  useEffect(() => {
    if (selectedDeviceSelectedDayData.length == 0) return;
      deviceDetails(deviceId)
        .then((res) => {
          setDeviceDetails(res);
        })
        .catch((err) => {
          setDeviceDetails({});
        });
    
  }, [deviceId]);
  if (selectedDeviceSelectedDayData.length == 0) return;
 
  const isConnected = selectedDeviceSelectedDayData[0].connected;
  const hasStateLogs = selectedDeviceSelectedDayData[0].stateLogs?.length > 0;
  let isLastLogOff = true

  

  const isDifferentDate = (new Date(selectedDate).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0))

  const value = isDifferentDate || !isConnected ? "Offline" : (deviceDetail.applianceState===0 ? "Off" : "On")
  let nameObj = {
    'M280246':'Ac Status',
    "M240054":'Motion Status'
  }

  return (
    <DetailContainer
      name={nameObj[deviceId]?nameObj[deviceId]:"Appliance Status"}
      value={value}

      OnClick={() => {
        if (selectedDeviceSelectedDayData.length === 1) {
          if (new Date(selectedDate).setHours(0, 0, 0, 0) !==
            new Date().setHours(0, 0, 0, 0)) {
            // messages.success("Date is not toady")
            return
          }
          if (!isConnected) {
            messages.notify('Device is Offline')
            return
          }
          if (hasStateLogs && !isLastLogOff) {
            deviceControl(deviceId, 0)
          } else {
            deviceControl(deviceId, 100)
          }
        }
      }}
      styleValue={{ cursor: value === 'Offline' ? '' : 'pointer' }}
      time={time}
      color={"rgba(118, 190, 194, 0.10)"}
      borderColor={"#76BEC2"}
      image={images.ac}
    />
  )
}

export default ACStatusDetailContainer