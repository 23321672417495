import React, { useEffect, useState,useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid, TextField, Button, Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import SideBar from '../SideBar/SideBar';
import Header from '../Header/Header';
import Loading from '../common/Loading';
import store from '../redux';
import { fetchAndStoreMultipleDevicesTodayData, fetchAndStoreMulipleDevicesSelectedDayData } from '../redux/action/totalData';
import { selectedHouse as selectedHouseFromDropdown, selectedRoom as selectedRoomFromDropdown, selectedRoomDevices, selectedDeviceFromDropdown } from '../redux/action/deviceDropdownSelection';
import { housesListAction } from '../redux/action/housesList';
import { selectedHouse } from '../redux/actionTypes';
import axios from "axios";
import CarbonConsumeBarChart from "../Chart/BarCharts/CarbonConsumeBarChart";
import MoneySavedBarChart from "../Chart/BarCharts/MoneySavedBarChart";
import CarbonSavedBarChart from "../Chart/BarCharts/CarbonSavedBarChart";
import UnitConsumedIntervalLineChart from "../Chart/LineCharts/UnitConsumedIntervalLineChart";
import MoneySpentBarChart from "../Chart/BarCharts/MoneySpentBarChart";
import OnlineOfflineBarChart from "../Chart/BarCharts/OnlineOfflineBarChart";
import CurrentLineChart from "../Chart/LineCharts/CurrentLineChart";
import VoltageLineChart from "../Chart/LineCharts/VoltageLineChart";
import TemperatureLineChart from "../Chart/LineCharts/TemperatureLineChart";
import PowerConsumptionLineChart from "../Chart/LineCharts/PowerConsumptionLineChart";
import UnitSavingsDetailContainer from "../SystemValues/UnitSavings";
import VoltageDetailContainer from "../SystemValues/Voltage";
import PowerFactorDetailContainer from "../SystemValues/PowerFactor";
import ACStatusDetailContainer from "../SystemValues/ACStatus";
import OnlineStatusDetailContainer from "../SystemValues/OnlineStatus";
import ApplianceHealthDetailContainer from "../SystemValues/ApplianceHealth";
import UnitConsumedDetailContainer from "../SystemValues/UnitConsumed";
import UnitSavedDetailContainer from "../SystemValues/UnitSaved";
import MoneySavedDetailContainer from "../SystemValues/MoneySaved";
import MoneySpentDetailContainer from "../SystemValues/MoneySpent";
import CarbonSavedDetailContainer from "../SystemValues/CarbonSaved";
import MoneySavingDetailContainer from "../SystemValues/MoneySaving";
import { fetchAllHouses, fetchHouseDetails, updateDefaultHouse } from "../redux/action/house";
import { loginDetail } from "../redux/action/login";
import { houseData, housesList } from "../redux/actionTypes";
import UnitConsumedBarChart from "../Chart/BarCharts/UnitConsumedBarChart";
import PowerDetailContainer from "../SystemValues/Power";
import CurrentDetailContainer from "../SystemValues/Current";
import TemperatureDetailContainer from "../SystemValues/Temperature";
import TotalUnitsConsumedDetailContainer from "../SystemValues/TotalUnitsConsumed";
import UnitSavedBarChart from '../Chart/BarCharts/UnitSavedBarChart';
import { calculateGlobalDataa } from '../common/function';
import styles from './common/styles';
import dayjs from 'dayjs';

// export const styles = {
//     container: {
//         height: '100vh',
//         width: '100vw',
//         display: 'flex',
//     },
//     rightContainer: {
//         height: '100vh',
//         width: '100vw',
//         display: 'flex',
//         flexDirection: 'column',
//     },
// };

function SummaryPage() {
    const dispatch = useDispatch();
    // const { selectedDeviceDetails: { deviceId: selectedDeviceId } = {}, roomId, devices } = useSelector(state => state.dropdownSelectionData);
    // const houseData = useSelector(state => state.houseData);
    // const selectedDayData = useSelector((state) => state.selectedDayReducer);

    const snapshotUnit = useSelector(state => state.snapshotUnit)
    const housesList = useSelector(state => state.housesList);
    const loginDetails = useSelector((state) => state.login);
    const isInitialMount = useRef(true);

    const [loading, setLoading] = useState(false);
    const [dateA, setDateA] = useState(new Date());
    // const [house, setSelectedHouse] = useState({});
    // const [date, setDate] = useState(new Date().setHours(0, 0, 0, 0));
    // const [date, setDate] = useState('');
    const [date, setDate] = useState(dayjs());
    const [selectedDayAllDevicesData, setSelectedDayAllDevicesData] = useState([]);
    const [selectedHouse, setSelectedHouse] = useState({});
    const [selectedHouseDeviceIds, setSelectedHouseDeviceIds] = useState([]);
    const [systemValuesData, setSystemValuesData] = useState({
        globalMoneySaved: 0,
        globalMoneySpent: 0,
        globalUnitSaved: 0,
        globalUnitConsumed: 0,
        globalUnitSavingPercentge: 0,
        time: '12:00 AM'
    });
    const [graphData, setGraphData] = useState({
        moneySaved: [],
        moneySpent: [],
        online: [],
        carbonConsumed: [],
        carbonSaved: [],
        unitConsumed: [],
        unitSaved: [],
    });

    let ppu = selectedHouse.ppu !== undefined && selectedHouse.ppu > 0 ? selectedHouse.ppu : 15;

    useEffect(() => {
        const fetchData = async () => {
            let response = {};

            try {
                response = await axios.get(
                    `https://subscriptioncloud.alistetechnologies.com/api/fetch/house2/${loginDetails.profile.selectedHouse}/${loginDetails.profile.email}?user=${[loginDetails.profile.email]}`,
                );

                if (response.status === 200) {
                    setSelectedHouse(response.data);

                    console.log(selectedHouse, 'selectedHouse')
                } else {
                    return { success: false, message: 'Authentication Error' };
                }
            } catch (error) {
                console.error('Error fetching house data:', error);
            }

            const deviceIds = getDeviceIds(response.data.rooms)

            setSelectedHouseDeviceIds(deviceIds)

            setLoading(true);
            try {
                const response = await fetchAndStoreMultipleDevicesTodayData(deviceIds);

                setSelectedDayAllDevicesData(response)

            } catch (error) {
                console.error('Failed to fetch and store device data:', error);
            }
            setLoading(false);
        };

        fetchData();
        isInitialMount.current = false;

        return () => {
            // Cleanup logic if needed
        };
    }, []);

    //-------------------------------useEffect to calculate System Values and Graph Data---------------------------
    useEffect(() => {
        if (selectedDayAllDevicesData.length === 0) return;

        const { systemValues, graphData, phaseLoadLogs } = calculateGlobalDataa(selectedDayAllDevicesData, ppu);

        console.log(graphData, 'graphDatarrrr')

        // setPhaseLoadLogs({ ...phaseLoadLogs })
        setSystemValuesData({ ...systemValues });
        setGraphData({ ...graphData });

    }, [selectedDayAllDevicesData]);

    //-----------------------------------Handle Date Change-----------------------------------------
    const handleDateChange = async (newValue) => {

        // console.log(date,'daterrrr')

        const date = newValue.$d
        setDate(date)

        console.log(date, 'daterrrrfffff')

        const selectedDateInStringWithoutHours = new Date(date).setHours(0, 0, 0, 0);

        console.log(date, 'datedatedatedate')
        const todayDateInStringWithoutHours = new Date().setHours(0, 0, 0, 0);

        const isDifferentDate = selectedDateInStringWithoutHours !== todayDateInStringWithoutHours;

        let totalData = []
        setLoading(true);
        if (isDifferentDate) {

            console.log(selectedHouseDeviceIds,'selectedHouseDeviceIdsffff')
            for (const device of selectedHouseDeviceIds) {
                console.log(device,'devicebbbeee')

                const deviceData = snapshotUnit?.[device.deviceId]?.[new Date(selectedDateInStringWithoutHours).toDateString()];

                console.log(deviceData,'deviceData')
                if (deviceData) {
                    totalData.push(deviceData)

                } else {
                    console.log(device,'devicebbb')
                    const response = await fetchAndStoreMulipleDevicesSelectedDayData([device], date);
                    totalData.push(response[0])
                }
            }
        } else {
            console.log(selectedHouseDeviceIds, 'selectedHouseDeviceIds')
            totalData = await fetchAndStoreMultipleDevicesTodayData(selectedHouseDeviceIds);
        }
        setSelectedDayAllDevicesData(totalData)
        setLoading(false)
    };

    //-----------------------------------Handle House change for house dropdown selection-------------
    const handleHouseChange = async (event) => {
        setLoading(true);

        let response = {};

        try {
            response = await axios.get(
                `https://subscriptioncloud.alistetechnologies.com/api/fetch/house2/${loginDetails.profile.selectedHouse}/${loginDetails.profile.email}?user=${[loginDetails.profile.email]}`,
            );

            if (response.status === 200) {
                setSelectedHouse(response.data);
            } else {
                return { success: false, message: 'Authentication Error' };
            }
        } catch (error) {
            console.error('Error fetching house data:', error);
        }

        setSelectedHouseDeviceIds(getDeviceIds(response.data.rooms))

        const selectedDateInStringWithoutHours = new Date(date).setHours(0, 0, 0, 0);
        const todayDateInStringWithoutHours = new Date().setHours(0, 0, 0, 0);

        const isDifferentDate = selectedDateInStringWithoutHours !== todayDateInStringWithoutHours;

        console.log()

        let totalData = []
        if (isDifferentDate) {
            for (const device in selectedHouseDeviceIds) {

                const deviceData = snapshotUnit?.[device.deviceId]?.[new Date(selectedDateInStringWithoutHours).toDateString()];

                if (deviceData) {
                    totalData.push(deviceData)

                } else {
                    const response = await fetchAndStoreMulipleDevicesSelectedDayData([device], date);
                    totalData.push(response[0])
                }
            }
        } else {
            console.log(selectedHouseDeviceIds, 'selectedHouseDeviceIds')
            totalData = await fetchAndStoreMultipleDevicesTodayData(selectedHouseDeviceIds);
        }
        setSelectedDayAllDevicesData(totalData)
        setLoading(false)
    }


    //--------------------------------------------------------------------------------------------------
    function getDeviceIds(data) {
        const deviceIds = [];

        data.forEach(device => {
            // Extract deviceId from devices
            device.energiSync.forEach(device => {
                if (device.deviceId) {
                    // Push the object with deviceId
                    deviceIds.push({ deviceId: device.deviceId });
                }
            });
        });

        return deviceIds;
    }


    //-----------------------------------------Main---------------------------------------------------------
    return (
        <div style={{ ...styles.container }}>
            {/* <SideBar /> */}
            <div style={{ ...styles.rightContainer }}>
                <Header />
                <div style={{ ...styles.container }}>
                    <Toaster />
                    {/*-----------------------------------Dropdown Section------------------------------------------*/}

                    {/*-----------------------Date Selection-------------------------*/}
                    <Row style={{ ...styles.smallBoxContainer }} className="row g-4 align-items-end" >
                        <Col md={3} sm={12}>
                            {/* <Box sx={{ minWidth: 120 }}> */}
                            <LocalizationProvider sx={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                                <DemoContainer sx={{ width: '100%' }} components={["DatePicker"]}>
                                    <DatePicker sx={{ width: '100%' }}
                                        disableFuture
                                        value={date}
                                        onChange={(newValue) => handleDateChange(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {/* </Box> */}
                        </Col>

                        {/*-----------------------House/Property Selection-------------------------*/}
                        <Col md={3} sm={12}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="house-select-label">Property</InputLabel>
                                    <Select
                                        labelId="house-select-label"
                                        id="house-select"
                                        value={selectedHouse._id || ''}
                                        label="Property"
                                        onChange={handleHouseChange}
                                    >
                                        {housesList
                                            .map(house => (
                                                <MenuItem key={house.houseName} value={house.houseAccessCode}>
                                                    {house.houseName}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Col>

                    </Row>

                    {selectedDayAllDevicesData.length !== 0
                        // && houseHasEnergySyncDevices
                        && (
                            <>
                              {/*-----------------------------------System Container Section------------------------------------------*/}
                              <Row style={{ marginBottom: "20px" }}>
                                    <Col md={6} sm={12}>
                                        <div style={{ ...styles.chartContainer }}>
                                            <UnitSavedBarChart carbonSavedData={graphData.carbonSaved} />
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <div style={{ ...styles.chartContainer }}>
                                            <UnitConsumedBarChart carbonConsumedData={graphData.carbonConsumed} />
                                        </div>
                                    </Col>
                                </Row>
                                {/*-----------------------------------Charts Section------------------------------------------*/}
                                <Row style={{ marginBottom: "20px" }}>
                                    <Col md={6} sm={12}>
                                        <div style={{ ...styles.chartContainer }}>
                                            <UnitSavedBarChart carbonSavedData={graphData.carbonSaved} />
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <div style={{ ...styles.chartContainer }}>
                                            <UnitConsumedBarChart carbonConsumedData={graphData.carbonConsumed} />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                </div>
            </div>

            {loading && <Loading />}
        </div>

    );
}

export default SummaryPage;
