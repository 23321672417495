import moment from "moment"
import store from ".."
// import { postAllDataAws } from "../../utils/Routes"
import { snapshotDailyUnit } from "../../redux/actionTypes"

export const loadUnitSnapshot = (data) => {
    return {
        type: snapshotDailyUnit.LOAD,
        payload: data
    }
}

// export function findDatesBetween(startDate, endDate) {
//     // Create an array to store the dates between start and end dates
//     var dates = [];

//     // Convert start and end dates to JavaScript Date objects
//     var currentDate = new Date(startDate);
//     var lastDate = new Date(endDate);

//     // Loop through each date starting from the start date until the end date
//     while (currentDate <= lastDate) {
//         // Add the current date to the array
//         dates.push(new Date(currentDate));

//         // Move to the next day
//         currentDate.setDate(currentDate.getDate() + 1);
//     }

//     // Return the array of dates
//     return dates;
// }

// const findDataInLocalStorage = (data,id,date)=>{
//     if(moment(new Date(date)).format("DD MM YYYY")===moment(new Date()).format("DD MM YYYY")){
//         return false
//     }
//     if(data[id]===undefined){
//         return false
//     }else if(Object.keys(data[id]).length===0){
//         return false
//     }else if(data[id][date]===undefined){
//         return false
//     }else if(Object.keys(data[id][date]).length===0){
//         return false
//     }else{

//         return true
//     }
// }

export const findDataInLocalStorage = (data, id, date) => {
    // Check if date is today or one of the two previous days
    // const currentDate = moment().startOf('day');
    // const targetDate = moment(date).startOf('day');
    // if (targetDate.isSameOrAfter(currentDate.subtract(2, 'days'))) {
    //     return false;
    // }
    // if (moment(date).isSame(moment(), 'day')) {
    //     return false; // If date is today, no need to check localStorage
    // }

    // Check if data for the given id and date exists
    const idData = data[id];
    if (!idData || !idData[date]) {
        return false;
    }



    return Object.keys(idData[date]).length > 0;
};


// export const makeDatesFromRange = async(dateRage,deviceIds)=>{

//    let snapshotUnit = store.getState().snapshotUnit
//    var startDate = new Date(dateRage.startDate).setHours(0,0,0,0)
// var endDate = new Date(dateRage.endDate).setHours(0,0,0,0)
// var datesBetween = findDatesBetween(startDate, endDate);

//    for(let date of datesBetween){
//     for(let id of deviceIds){
//        findDataInLocalStorage(snapshotUnit,id,date) ?"":await fetchDailyUnitsSnapshot(id,date)
//     }
//    }
// }

// const fetchDailyUnitsSnapshot =async (id,date)=>{
//     try {
//       let res = await postAllDataAws('/units/day',{
//             "deviceId": id,
//             "day": date
//         })
//         if(res.data.success){
//            store.dispatch(loadUnitSnapshot({data:res.data.data.snapshot,deviceId:id,date:date}))
//           return
//         }else{
//             return
//         }
//     } catch (error) {
//         return
//     }
// }