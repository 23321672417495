import { environSyncsAction, weatherSensorsAction } from "./component/redux/actionTypes"
import { schedules } from "./component/redux/reducer/schedule"

export const serverUrl={
    aws:'https://pyd0xvkp5g.execute-api.ap-south-1.amazonaws.com/default',
    web:'https://web.alistetechnologies.com',
    sub:'https://subscriptioncloud.alistetechnologies.com',
    thermoAws:'https://q2c544kuqd.execute-api.ap-south-1.amazonaws.com',
    weatherAws:'https://hp92ovh6r0.execute-api.ap-south-1.amazonaws.com/default',
    environAws:'https://mzcdl7akp0.execute-api.ap-south-1.amazonaws.com'
}

export const pages = {
    schedulePage:'schedule'
}