import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Grid,
  TextField,
  Button,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { calculateGlobalData, calculateTime } from "../common/function";
import CarbonConsumeBarChart from "../Chart/BarCharts/CarbonConsumeBarChart";
import MoneySavedBarChart from "../Chart/BarCharts/MoneySavedBarChart";
import CarbonSavedBarChart from "../Chart/BarCharts/CarbonSavedBarChart";
import UnitConsumedIntervalLineChart from "../Chart/LineCharts/UnitConsumedIntervalLineChart";
import MoneySpentBarChart from "../Chart/BarCharts/MoneySpentBarChart";
import OnlineOfflineBarChart from "../Chart/BarCharts/OnlineOfflineBarChart";
import CurrentLineChart from "../Chart/LineCharts/CurrentLineChart";
import VoltageLineChart from "../Chart/LineCharts/VoltageLineChart";
import TemperatureLineChart from "../Chart/LineCharts/TemperatureLineChart";
import PowerConsumptionLineChart from "../Chart/LineCharts/PowerConsumptionLineChart";
import UnitSavingsDetailContainer from "../SystemValues/UnitSavings";
import VoltageDetailContainer from "../SystemValues/Voltage";
import PowerFactorDetailContainer from "../SystemValues/PowerFactor";
import ACStatusDetailContainer from "../SystemValues/ACStatus";
import OnlineStatusDetailContainer from "../SystemValues/OnlineStatus";
import ApplianceHealthDetailContainer from "../SystemValues/ApplianceHealth";
import UnitConsumedDetailContainer from "../SystemValues/UnitConsumed";
import UnitSavedDetailContainer from "../SystemValues/UnitSaved";
import MoneySavedDetailContainer from "../SystemValues/MoneySaved";
import MoneySpentDetailContainer from "../SystemValues/MoneySpent";
import CarbonSavedDetailContainer from "../SystemValues/CarbonSaved";
import MoneySavingDetailContainer from "../SystemValues/MoneySaving";
import store from "../redux";
import {
  selectedRoom as selectedRoomFromDropdown,
  selectedRoomDevices,
  selectedDeviceFromDropdown,
  selectedHouse,
} from "../redux/action/deviceDropdownSelection";
import { selectedDay } from "../redux/action/selectedDate";
import styles from "./common/styles";
import {
  fetchAndStoreMultipleDevicesTodayData,
  fetchAndStoreMulipleDevicesSelectedDayData,
} from "../redux/action/totalData";
import UnitSavedBarChart from "../Chart/BarCharts/UnitSavedBarChart";
import {
  fetchAllHouses,
  fetchHouseDetails,
  updateDefaultHouse,
} from "../redux/action/house";
import { loginDetail } from "../redux/action/login";
import { houseData, housesList } from "../redux/actionTypes";
import UnitConsumedBarChart from "../Chart/BarCharts/UnitConsumedBarChart";
import PowerDetailContainer from "../SystemValues/Power";
import CurrentDetailContainer from "../SystemValues/Current";
import TemperatureDetailContainer from "../SystemValues/Temperature";
import TotalUnitsConsumedDetailContainer from "../SystemValues/TotalUnitsConsumed";
import { type } from "@testing-library/user-event/dist/type";
import { config } from "../../config";
import { environAwsApi, thermoAwsApi, weatherAwsApi } from "../common/ApiCall";
import WeatherControllog from "../Chart/LineCharts/WeatherControllog";
import WeattherValue from "../SystemValues/WeattherValue";
import ThermoSmartStateSystemValue from "../ThermoSmartStateSystemValue";

function MainV2({ setLoading, dateA, setDateA, setRoomCount, roomCount }) {
  //----------------------------------------Redux data----------------------------------------------------
  const houseData = useSelector((state) => state.houseData);
  const { houseId, selectedDeviceDetails, roomId, devices } = useSelector(
    (state) => state.dropdownSelectionData || {}
  );
  const selectedDeviceId = selectedDeviceDetails
    ? selectedDeviceDetails.deviceId
    : undefined;
  const login = useSelector((state) => state.login);
  const selectedDayData = useSelector((state) => state.selectedDayReducer);
  const selectedDate = useSelector((state) => state.selectedDate);
  const snapshotUnit = useSelector((state) => state.snapshotUnit);
  const housesList = useSelector((state) => state.housesList);

  console.log(selectedDayData, "selectedDayDataaaa");

  //----------------------------------------Use state----------------------------------------------------
  const [selectedDeviceSelectedDayData, setSelectedDeviceSelectedDayData] = useState([]); // most important variable as from this only all the data of the selected device is used

  // const [details, setDetails] = useState({
  //   startTime: "",
  //   endTime: "",
  //   deviceId: '',
  // });

  const [systemValuesData, setSystemValuesData] = useState({
    globalMoneySaved: 0,
    globalMoneySpent: 0,
    globalUnitSaved: 0,
    globalUnitConsumed: 0,
    globalUnitSavingPercentge: 0,
    time: "12:00 AM",
  });

  let ppu =
    houseData.ppu !== undefined && houseData.ppu > 0 ? houseData.ppu : 15;

  const [graphData, setGraphData] = useState({
    moneySaved: [],
    moneySpent: [],
    online: [],
    carbonConsumed: [],
    carbonSaved: [],
    unitConsumed: [],
    unitSaved: [],
  });

  const [phaseLoadLogs, setPhaseLoadLogs] = useState({});

  const [houseHasEnergySyncDevices, setHouseHasEnergySyncDevices] =
    useState(true);

  //------------------------------------useEffect for snapshot saved in redux--------------------
  useEffect(() => {
    if (Object.keys(snapshotUnit).length === 0) return;
    console.log('====================================');
    console.log(snapshotUnit,"vl",snapshotUnit[selectedDeviceId] &&
    snapshotUnit[selectedDeviceId],selectedDate);
    console.log('====================================');
    const selectedDateInStringWithoutHours = new Date(
      new Date(selectedDate).setHours(0, 0, 0, 0)
    ).toDateString();
   
    if (
      snapshotUnit[selectedDeviceId] &&
      snapshotUnit[selectedDeviceId][selectedDateInStringWithoutHours]
    ) {
      setSelectedDeviceSelectedDayData([
        snapshotUnit[selectedDeviceId][selectedDateInStringWithoutHours],
      ]);
    }
  }, [snapshotUnit]);

  //-------------------------------useEffect to calculate System Values and Graph Data---------------------------
  useEffect(() => {
    if (selectedDeviceSelectedDayData.length === 0) return;
    if (Object.keys(selectedDeviceDetails).length === 0) return;

    const { systemValues, graphData, phaseLoadLogs } = calculateGlobalData(
      selectedDeviceSelectedDayData,
      ppu,
      selectedDeviceDetails
    );

    console.log(phaseLoadLogs, "phaseLoadLogs");

    setPhaseLoadLogs({ ...phaseLoadLogs });
    setSystemValuesData({ ...systemValues });
    setGraphData({ ...graphData });
  }, [selectedDeviceSelectedDayData, selectedDeviceDetails]);

  //-----------------------------------Handle Date Change-----------------------------------------
  const handleDateChange = async (newValue) => {
    console.log(newValue.$d, "newValue", selectedDeviceDetails);
    store.dispatch(selectedDay(newValue.$d));

    await fetchDataForDeviceAndDate(
      selectedDeviceId,
      selectedDeviceDetails.type
    );
  };

  //-----------------------------------Handle House change for house dropdown selection-------------
  const handleHouseChange = async (event) => {
    setLoading(true);
    setHouseHasEnergySyncDevices(true);
    const selectedHouseID = event.target.value;

    console.log(selectedHouseID, "selectedHouseID");

    const loginDetails = store.getState().login;

    console.log(loginDetails, "loginDetails");

    const mobileWithoutCountryCode = loginDetails.profile.mobile.slice(3);

    console.log(new Date(store.getState().selectedDate).toDateString());

    await fetchHouseDetails(selectedHouseID, mobileWithoutCountryCode);

    console.log(new Date(store.getState().selectedDate).toDateString());

    const houseDetails = store.getState().houseData;

    //Update default house so that next time when user logins, the last clicked device is set as default house
    // await updateDefaultHouse(selectedHouseID, mobileWithoutCountryCode)

    // const roomsWithNonEmptyEnergySync = houseDetails?.rooms?.filter(room => room.energiSync.length > 0);
    const roomsWithNonEmptyEnergySync = houseDetails?.rooms?.filter(
      (room) =>
        room.energiSync.length > 0 ||
        room.weatherSensors.length > 0 ||
        room.thermoSmarts.length > 0 ||
        room.environSyncs.length > 0
    );
    console.log("====================================");
    console.log(roomsWithNonEmptyEnergySync, "room h");
    console.log("====================================");
    if (roomsWithNonEmptyEnergySync.length === 0) {
      setRoomCount(0);
      setLoading(false);
      setHouseHasEnergySyncDevices(false);
      store.dispatch(selectedHouse(selectedHouseID));
      store.dispatch(selectedRoomFromDropdown(""));
      store.dispatch(selectedRoomDevices([]));

      return;
    }

    setRoomCount(roomsWithNonEmptyEnergySync.length);

    const defaultRoom = roomsWithNonEmptyEnergySync[0]._id;
    console.log("====================================");
    console.log(roomsWithNonEmptyEnergySync[0], "room h default");
    console.log("====================================");
    const defaultRoomAllDevices = [
      ...roomsWithNonEmptyEnergySync[0].energiSync.map((e) => {
        return { ...e, type: config.deviceType.energiSync };
      }),
      ...roomsWithNonEmptyEnergySync[0].thermoSmarts.map((e) => {
        return { ...e, type: config.deviceType.thermoSmarts };
      }),
      ...roomsWithNonEmptyEnergySync[0].weatherSensors.map((e) => {
        return { ...e, type: config.deviceType.weatherSensors };
      }),
      ...roomsWithNonEmptyEnergySync[0].environSyncs.map((e) => {
        return { ...e, type: config.deviceType.environSyncs };
      }),
    ];
    const defaultDeviceDetails = defaultRoomAllDevices[0];

    store.dispatch(selectedHouse(selectedHouseID));
    store.dispatch(selectedRoomFromDropdown(defaultRoom));
    store.dispatch(selectedRoomDevices(defaultRoomAllDevices));
    store.dispatch(selectedDeviceFromDropdown(defaultDeviceDetails));

    await fetchDataForDeviceAndDate(
      defaultDeviceDetails?.deviceId,
      defaultDeviceDetails?.type
    );
  };

  //-----------------------------------Handle room change for room dropdown selection-------------
  const handleRoomChange = async (event) => {
    // setLoading(true);
    const selectedRoomID = event.target.value;

    // Find the selected room from the rooms array
    const selectedRoomData = houseData?.rooms?.find(
      (room) => room._id === selectedRoomID
    );
    if (selectedRoomData) {
      const devicesData = [
        ...selectedRoomData.energiSync.map((e) => {
          return { ...e, type: config.deviceType.energiSync };
        }),
        ...selectedRoomData.thermoSmarts.map((e) => {
          return { ...e, type: config.deviceType.thermoSmarts };
        }),
        ...selectedRoomData.weatherSensors.map((e) => {
          return { ...e, type: config.deviceType.weatherSensors };
        }),
        ...selectedRoomData.environSyncs.map((e) => {
          return { ...e, type: config.deviceType.environSyncs };
        }),
      ];
      const defaultDeviceDetailsOfSelectedRoom = devicesData[0];
      const defaultDeviceOfSelectedRoom = devicesData[0].deviceId;
      store.dispatch(selectedRoomFromDropdown(selectedRoomID));
      store.dispatch(selectedRoomDevices(devicesData));
      store.dispatch(
        selectedDeviceFromDropdown(defaultDeviceDetailsOfSelectedRoom)
      );

      await fetchDataForDeviceAndDate(
        defaultDeviceOfSelectedRoom,
        devicesData[0].type
      );
    }
  };

  //-----------------------------------Handle device change for device dropdown selection ----------
  const handleDeviceChange = async (event) => {
    const deviceDetails = JSON.parse(event.target.value);

    store.dispatch(selectedDeviceFromDropdown(deviceDetails));

    await fetchDataForDeviceAndDate(deviceDetails.deviceId, deviceDetails.type);
  };

  //-------------------------- Common function to handle data fetching-------------------------------
  const fetchDataForDeviceAndDate = async (deviceId, type) => {

    const selectedDate = store.getState().selectedDate;
    const selectedDateInStringWithoutHours = new Date(selectedDate).setHours(
      0,
      0,
      0,
      0
    );
    const todayDateInStringWithoutHours = new Date().setHours(0, 0, 0, 0);

    const isDifferentDate =
      selectedDateInStringWithoutHours !== todayDateInStringWithoutHours;

    if (!isDifferentDate) {
      setLoading(true);
      if (type === config.deviceType.energiSync) {
        await fetchAndStoreMultipleDevicesTodayData([{ deviceId }]);
      } else if (type === config.deviceType.environSyncs) {
        await environAwsApi(deviceId, todayDateInStringWithoutHours);
      } else if (type === config.deviceType.thermoSmarts) {
        await thermoAwsApi(deviceId, todayDateInStringWithoutHours);
      } else if (type === config.deviceType.weatherSensors) {
        await weatherAwsApi(deviceId, todayDateInStringWithoutHours);
      }
    } else {
      const deviceData =
        snapshotUnit?.[deviceId]?.[
          new Date(selectedDateInStringWithoutHours).toDateString()
        ];

      if (deviceData) {
        setSelectedDeviceSelectedDayData([deviceData]);
      } else {
        setLoading(true);
        if (type === config.deviceType.energiSync) {
          await fetchAndStoreMulipleDevicesSelectedDayData(
            [{ deviceId }],
            selectedDate
        )} else if (type === config.deviceType.environSyncs) {
          await environAwsApi(deviceId, selectedDate);
        } else if (type === config.deviceType.thermoSmarts) {
          await thermoAwsApi(deviceId, selectedDate);
        } else if (type === config.deviceType.weatherSensors) {
          await weatherAwsApi(deviceId, selectedDate);
        }
      }
    }
    setLoading(false);
  };

  //----------------------------------------------------------------------------------------------------
  const systemValuesContainerComponents = {
    temperature: (
      <TemperatureDetailContainer
        key="temperature"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    onlineStatus: (
      <OnlineStatusDetailContainer
        key="onlineStatus"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    ACStatus: (
      <ACStatusDetailContainer
        key="ACStatus"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        selectedDate={selectedDate}
        deviceId={selectedDeviceDetails?.deviceId}
      />
    ),
    applianceHealth: (
      <ApplianceHealthDetailContainer
        key="applianceHealth"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    unitConsumed: (
      <TotalUnitsConsumedDetailContainer
        key="unitConsumed"
        time={systemValuesData.time}
        unitsConsumed={systemValuesData.globalUnitConsumed}
      />
    ),
    unitSaved: (
      <UnitSavedDetailContainer
        key="unitSaved"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        unitsSaved={systemValuesData.globalUnitSaved}
      />
    ),
    moneySpent: (
      <MoneySpentDetailContainer
        key="moneySpent"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        moneySpent={systemValuesData.globalMoneySpent}
      />
    ),
    moneySaved: (
      <MoneySavedDetailContainer
        key="moneySaved"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        moneySaved={systemValuesData.globalMoneySaved}
      />
    ),
    moneySaving: (
      <MoneySavingDetailContainer
        key="moneySaving"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    unitSaving: (
      <UnitSavingsDetailContainer
        key="unitSaving"
        time={systemValuesData.time}
        unitsSaved={systemValuesData.globalUnitSaved}
        unitsConsumed={systemValuesData.globalUnitConsumed}
      />
    ),
    carbonSaving: (
      <CarbonSavedDetailContainer
        key="carbonSaving"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        carbonSavedData={graphData.carbonSaved}
        carbonConsumedData={graphData.carbonConsumed}
      />
    ),
  };
  const systemValueThermoContainerComponents = {
    onlineStatus: (
      <OnlineStatusDetailContainer
        key="onlineStatus"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    temperature: (
      <TemperatureDetailContainer
        key="temperature"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    thermoControl:(
        <ThermoSmartStateSystemValue
        key="control"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        dataKey={"controlLogs"}
        valueKey={"state"}
        name={"Control"}
        />
      )
  };
  const systemValuesWeaherContainerComponents = {
   
    onlineStatus: (
      <OnlineStatusDetailContainer
        key="onlineStatus"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    temperature: (
      <TemperatureDetailContainer
        key="temperature"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    Humidity: (
      <WeattherValue
        key="Humidity"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"humidityLogs"}
        valueKey={"h"}
        name={"Humidity"}
        timeKey={"ts"}
        degree={' AH'}
      />
    ),
  };
  const systemValueEnvirnoContainerComponents = {
 
    onlineStatus: (
      <OnlineStatusDetailContainer
        key="onlineStatus"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    temperature: (
      <TemperatureDetailContainer
        key="temperature"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
      />
    ),
    Humidity: (
      <WeattherValue
        key="Humidity"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"humidityLogs"}
        valueKey={"h"}
        name={"Humidity"}
        timeKey={"ts"}
        degree={' AH'}
      />
    ),
    Co2Level: (
      <WeattherValue
        key="Co2Level"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"co2Logs"}
        valueKey={"co2"}
        name={"Co2 Level"}
        timeKey={"ts"}
        toolTip={"Co2"}
        degree={" Co2"}
      />
    ),
    airQualityIndex: (
      <WeattherValue
        key="airQualityIndex"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"aqiLogs"}
        valueKey={"aqi"}
        name={"Air Quality Index"}
        timeKey={"ts"}
        toolTip={"AQI"}
        degree={" AQI"}
      />
    ),
    equivalentCarbonDioxide: (
      <WeattherValue
        key="equivalentCarbonDioxide"
        time={systemValuesData.time}
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"aqiLogs"}
        valueKey={"eco2"}
        name={"Equivalent CO2"}
        timeKey={"ts"}
        toolTip={"eCO2"}
        degree={" eCO2"}
      />
    ),
  };
  //----------------------------------------------------------------------------------------------------------
  const chartComponents = {
    carbonSaved: (
      <CarbonSavedBarChart
        key="carbonConsumed"
        carbonSavedData={graphData.carbonSaved}
        stacked="true"
      />
    ),
    carbonConsumed: (
      <CarbonConsumeBarChart
        key="carbonSaved"
        carbonConsumedData={graphData.carbonConsumed}
        stacked="true"
      />
    ),
    unitSaved: (
      <UnitSavedBarChart
        key="unitSaved"
        carbonSavedData={graphData.carbonSaved}
        stacked="true"
      />
    ),
    unitConsumed: (
      <UnitConsumedBarChart
        key="unitConsumed"
        carbonConsumedData={graphData.carbonConsumed}
        stacked="true"
      />
    ),
    moneySaved: (
      <MoneySavedBarChart
        key="moneySaved"
        carbonSavedData={graphData.carbonSaved}
        ppu={ppu}
        stacked="true"
      />
    ),
    moneySpent: (
      <MoneySpentBarChart
        key="moneySpent"
        carbonConsumedData={graphData.carbonConsumed}
        ppu={ppu}
        stacked="true"
      />
    ),
    temperature: (
      <TemperatureLineChart
        key="temperature"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
      />
    ),
    power: (
      <PowerConsumptionLineChart
        key="power"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        deviceId={selectedDeviceDetails?.deviceId}
      />
    ),
    voltage: (
      <VoltageLineChart
        key="voltage"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        deviceId={selectedDeviceDetails?.deviceId}
        stacked="true"
      />
    ),
    current: (
      <CurrentLineChart
        key="current"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        deviceId={selectedDeviceDetails?.deviceId}
        stacked="true"
      />
    ),
  };
  const thermoChartComponent = {
    temperature: (
      <TemperatureLineChart
        key="temperature"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
      />
    ),
    controlLog: (
      <WeatherControllog
        key="control"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"controlLogs"}
        valueKey={"state"}
        name={"Control"}
        timeKey={"timestamp"}
        toolTip={"State"}
        yAxis={"Command"}
      />
    ),
   
  };
  const weatherChartComponent = {
    temperature: (
      <TemperatureLineChart
        key="temperature"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
      />
    ),
    Humidity: (
      <WeatherControllog
        key="Humidity"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"humidityLogs"}
        valueKey={"h"}
        name={"Humidity"}
        timeKey={"ts"}
        toolTip={"Humidity"}
        yAxis={"Absolute Humidity"}
      />
    ),
  };
  const environChartComponent = {
    temperature: (
      <TemperatureLineChart
        key="temperature"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
      />
    ),
    Humidity: (
      <WeatherControllog
        key="Humidity"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"humidityLogs"}
        valueKey={"h"}
        name={"Humidity"}
        timeKey={"ts"}
        toolTip={"Humidity"}
        yAxis={"Absolute Humidity"}
      />
    ),
    Co2Level: (
      <WeatherControllog
        key="Co2Level"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"co2Logs"}
        valueKey={"co2"}
        name={"Co2 Level"}
        timeKey={"ts"}
        toolTip={"Co2"}
        yAxis={"Co2"}
      />
    ),
    airQualityIndex: (
      <WeatherControllog
        key="airQualityIndex"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"aqiLogs"}
        valueKey={"aqi"}
        name={"Air Quality Index"}
        timeKey={"ts"}
        toolTip={"AQI"}
        yAxis={"AQI"}
      />
    ),
    equivalentCarbonDioxide: (
      <WeatherControllog
        key="equivalentCarbonDioxide"
        selectedDeviceSelectedDayData={selectedDeviceSelectedDayData}
        stacked="true"
        dataKey={"aqiLogs"}
        valueKey={"eco2"}
        name={"Equivalent carbon dioxide"}
        timeKey={"ts"}
        toolTip={"eCO2"}
        yAxis={"eCO2"}
      />
    ),
  };

  //-----------------------------------------Main---------------------------------------------------------
  return (
    <div style={{ ...styles.container }}>
      <Toaster />
      {/*-----------------------------------Dropdown Section------------------------------------------*/}

      {/*-----------------------Date Selection-------------------------*/}
      <Row
        style={{ ...styles.smallBoxContainer }}
        className="row g-4 align-items-end"
      >
        <Col md={3} sm={12}>
          {/* <Box sx={{ minWidth: 120 }}> */}
          <LocalizationProvider
            sx={{ width: "100%" }}
            dateAdapter={AdapterDayjs}
          >
            <DemoContainer sx={{ width: "100%" }} components={["DatePicker"]}>
              <DatePicker
                sx={{ width: "100%" }}
                disableFuture
                value={selectedDayData}
                onChange={(newValue) => handleDateChange(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          {/* </Box> */}
        </Col>

        {/*-----------------------House/Property Selection-------------------------*/}
        <Col md={3} sm={12}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="house-select-label">Property</InputLabel>
              <Select
                labelId="house-select-label"
                id="house-select"
                value={houseId || ""}
                label="Property"
                onChange={handleHouseChange}
              >
                {housesList
                  .sort((a, b) =>
                    a.houseName
                      .toLowerCase()
                      .localeCompare(b.houseName.toLowerCase())
                  )
                  .map((house) => (
                    <MenuItem
                      key={house.houseName}
                      value={house.houseAccessCode}
                    >
                      {house.houseName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Col>

        {/*-----------------------Room Selection-------------------------*/}
        {roomCount > 1 && (
          <Col md={3} sm={12}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="room-select-label">Room</InputLabel>
                <Select
                  labelId="room-select-label"
                  id="room-select"
                  value={roomId || ""}
                  label="Room"
                  onChange={handleRoomChange}
                >
                  {houseData?.rooms
                    ?.filter(
                      (room) =>
                        room.energiSync.length > 0 ||
                        room.weatherSensors.length > 0 ||
                        room.thermoSmarts.length > 0 ||
                        room.environSyncs.length > 0
                    )
                    .sort((a, b) =>
                      a.roomName.localeCompare(b.roomName, undefined, {
                        numeric: true,
                      })
                    )
                    .map((room) => (
                      <MenuItem key={room.roomName} value={room._id}>
                        {room.roomName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Col>
        )}

        {/*-----------------------Device/Appliance Selection-------------------------*/}
        {houseHasEnergySyncDevices && (
          <Col md={3} sm={12}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="device-select-label">Appliance</InputLabel>
                <Select
                  labelId="device-select-label"
                  id="device-select"
                  // value={selectedDeviceId}
                  value={JSON.stringify(selectedDeviceDetails)}
                  label="Appliance"
                  onChange={handleDeviceChange}
                >
                  {devices?.map((device) => (
                    <MenuItem
                      key={device.deviceId}
                      value={JSON.stringify(device)}
                    >
                      {device.name ? `${device.name} - ` : ""}
                      {device.deviceId}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Col>
        )}

        {/*-----------------------Aliste Specific Selection-------------------------*/}
        {/* {login.userName === "aliste" && (
          <>
            <Grid item xs={8} md={2} sx={{ marginTop: "8px" }}>
              <TextField
                id="outlined-basic"
                label="Device id"
                variant="outlined"
                onChange={(e) => setDeviceId(e.target.value)}
                value={deviceId}
              />
            </Grid>
            <Grid item xs={8} md={2} sx={{ marginTop: "8px" }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  if (
                    new Date(dateA).setHours(0, 0, 0, 0) !==
                    new Date().setHours(0, 0, 0, 0)
                  ) {
                    fetchAndStoreMulipleDevicesSelectedDayData([{ deviceId: deviceId }], dateA);
                    if (selectedDeviceId === "M240002") {
                      loadDataofDevice2Day("M250001", dateA);
                    }
                  } else {

                    fetchAndStoreMultipleDevicesTodayData([{ deviceId: deviceId }]);
                    if (selectedDeviceId === "M240002") {
                      loadDataofDevice2("M250001");
                    }
                  }
                }}
              >
                Get Details
              </Button>
            </Grid>
          </>
        )} */}
      </Row>

      {selectedDeviceSelectedDayData.length !== 0 &&
        houseHasEnergySyncDevices && (
          <>
            {/*----------------------------------System Values Section------------------------------------------*/}
            {/* Energy Sync */}
            {console.log(selectedDeviceDetails, "selectedDeviceDetails")}
            {selectedDeviceDetails.type === config.deviceType.energiSync && (
              <>
                <Row
                  style={{ ...styles.smallBoxContainer }}
                  className="row g-4"
                >
                  {
                    //This portion of the code is for those system value containers which can have multiple values depending upon multiple phases in that device
                    Object.keys(phaseLoadLogs).length !== 0 &&
                      phaseLoadLogs[selectedDeviceId] &&
                      Object.keys(phaseLoadLogs[selectedDeviceId]).map(
                        (phase) => {
                          const { data = [], unit } =
                            phaseLoadLogs[selectedDeviceId][phase] || {};
                          const systemValues =
                            selectedDeviceDetails.systemValues || [];
                          const components = {
                            voltage: VoltageDetailContainer,
                            current: CurrentDetailContainer,
                            powerFactor: PowerFactorDetailContainer,
                            power: PowerDetailContainer,
                          };
                          return (
                            <>
                              {systemValues.map((value) => {
                                const Component = components[value];
                                return Component ? (
                                  <Component
                                    key={`${phase}-${value}`}
                                    phaseLoadLogs={phaseLoadLogs}
                                    phaseWiseData={data}
                                    phase={phase}
                                    deviceId={selectedDeviceId}
                                  />
                                ) : null;
                              })}
                              {Object.keys(phaseLoadLogs[selectedDeviceId])
                                .length > 1 && (
                                <UnitConsumedDetailContainer
                                  phaseLoadLogs={phaseLoadLogs}
                                  phaseWiseData={data}
                                  phase={phase}
                                  deviceId={selectedDeviceId}
                                  unit={unit}
                                />
                              )}
                            </>
                          );
                        }
                      )
                  }
                </Row>

                <Row
                  style={{ ...styles.smallBoxContainer }}
                  className="row g-4"
                >
                  {selectedDeviceDetails?.systemValues
                    ? selectedDeviceDetails.systemValues.length > 0
                      ? selectedDeviceDetails.systemValues.map((value) =>
                          value === "temperature" &&
                          selectedDeviceDetails.systemValues.temperature
                            ? systemValuesContainerComponents["temperature"]
                            : systemValuesContainerComponents[value]
                            ? systemValuesContainerComponents[value]
                            : null
                        )
                      : null
                    : Object.keys(systemValuesContainerComponents).map((key) =>
                        key === "temperature" &&
                        !selectedDeviceDetails?.systemValues?.temperature
                          ? null
                          : systemValuesContainerComponents[key]
                      )}
                </Row>
              </>
            )}
            {/* Thermo Chart */}
            {console.log(selectedDeviceDetails?.systemValues?.temperature,"selectedDeviceDetails?.systemValues?.temperature")}
            {selectedDeviceDetails.type === config.deviceType.thermoSmarts && (
              <Row style={{ ...styles.smallBoxContainer }} className="row g-4">
                {Object.keys(systemValueThermoContainerComponents).map((key) =>
                     systemValueThermoContainerComponents[key]
                )}
              </Row>
            )}


            {/* Weather Chart */}
            {selectedDeviceDetails.type === config.deviceType.weatherSensors && (
              <Row style={{ ...styles.smallBoxContainer }} className="row g-4">
                {Object.keys(systemValuesWeaherContainerComponents).map((key) =>
                     systemValuesWeaherContainerComponents[key]
                )}
              </Row>
            )}

              {/* Environ Chart */}
              {console.log(selectedDeviceDetails?.systemValues?.temperature,"selectedDeviceDetails?.systemValues?.temperature")}
            {selectedDeviceDetails.type === config.deviceType.environSyncs && (
              <Row style={{ ...styles.smallBoxContainer }} className="row g-4">
                {Object.keys(systemValueEnvirnoContainerComponents).map((key) =>
                     systemValueEnvirnoContainerComponents[key]
                )}
              </Row>
            )}

            {/*-----------------------------------Charts Section------------------------------------------*/}
            {/* Energy Sync */}
            {selectedDeviceDetails.type === config.deviceType.energiSync && (
              <Row style={{ marginBottom: "20px" }}>
                {selectedDeviceDetails?.graphParameters
                  ? Object.keys(selectedDeviceDetails.graphParameters).length >
                    0
                    ? Object.keys(chartComponents).map((key) =>
                        selectedDeviceDetails.graphParameters.hasOwnProperty(
                          key
                        ) ? (
                          <Col key={key} md={6} sm={12}>
                            <div style={{ ...styles.chartContainer }}>
                              {chartComponents[key]}
                            </div>
                          </Col>
                        ) : null
                      )
                    : null
                  : Object.keys(chartComponents).map((key) => (
                      <Col key={key} md={6} sm={12}>
                        <div style={{ ...styles.chartContainer }}>
                          {chartComponents[key]}
                        </div>
                      </Col>
                    ))}
              </Row>
            )}

            {/* Thermo Chart */}
            {selectedDeviceDetails.type === config.deviceType.thermoSmarts && (
              <Row style={{ marginBottom: "20px" }}>
                {Object.keys(thermoChartComponent).map((key) => (
                  <Col key={key} md={6} sm={12}>
                    <div style={{ ...styles.chartContainer }}>
                      {thermoChartComponent[key]}
                    </div>
                  </Col>
                ))}
              </Row>
            )}

            {/* Weather Chart */}
            {selectedDeviceDetails.type ===
              config.deviceType.weatherSensors && (
              <Row style={{ marginBottom: "20px" }}>
                {Object.keys(weatherChartComponent).map((key) => (
                  <Col key={key} md={6} sm={12}>
                    <div style={{ ...styles.chartContainer }}>
                      {weatherChartComponent[key]}
                    </div>
                  </Col>
                ))}
              </Row>
            )}

            {/* Environ Syncs Chart */}
            {selectedDeviceDetails.type === config.deviceType.environSyncs && (
              <Row style={{ marginBottom: "20px" }}>
                {Object.keys(environChartComponent).map((key) => (
                  <Col key={key} md={6} sm={12}>
                    <div style={{ ...styles.chartContainer }}>
                      {environChartComponent[key]}
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </>
        )}
    </div>
  );
}

export default MainV2;
