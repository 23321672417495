import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { Update, Edit, Schedule, Thermostat, PowerSettingsNew, Settings as SettingsIcon, Share, Build, Notifications, Savings, ExpandLess, ExpandMore, CalendarMonthOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import './SettingsNavigation.css';
import BreadcrumbsComponent from '../Breadcrumb/Breadcrumb';

// Function to extract breadcrumbs from the URL
function extractBreadcrumbsFromPath(pathname) {
    const paths = pathname.split('/').filter(Boolean);
    return paths.map(segment => segment.charAt(0).toUpperCase() + segment.slice(1));
}

function CollapsibleSection({ title, items, setBreadcrumbs, activePath, setActivePath }) { // Added activePath and setActivePath
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const handleNavigation = (path, text) => {
        setBreadcrumbs([title, text]);
        setActivePath(path); // Update activePath state
        navigate(path);
    };

    return (
        <>
            <ListItem button onClick={() => setOpen(!open)} className={open ? 'active-section' : ''}>
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {items.map(({ text, icon: Icon, path }) => (
                        <ListItem
                            button
                            key={text}
                            onClick={() => handleNavigation(path, text)}
                            className={activePath === path ? 'active-item' : ''} // Apply active-item class if active
                        >
                            <ListItemIcon><Icon /></ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    );
}

function SettingsNavigation({ setBreadcrumbs }) {
    const location = useLocation();
    const [activePath, setActivePath] = useState(location.pathname); // Added state for active path

    useEffect(() => {
        const initialBreadcrumbs = extractBreadcrumbsFromPath(location.pathname);
        setBreadcrumbs(initialBreadcrumbs);
        setActivePath(location.pathname); // Set active path on location change
    }, [location, setBreadcrumbs]);

    const configurationItems = [
        { text: 'Update Wi-Fi', icon: Update, path: '/settings/update-wifi' },
        { text: 'Edit Names', icon: Edit, path: '/settings/edit-names' }
    ];

    const operationsItems = [
        { text: 'Schedule Operation', icon: CalendarMonthOutlined, path: '/settings/schedule-operation' },
        // { text: 'Temperature Trigger', icon: Thermostat, path: '/settings/temperature-trigger' },
        // { text: 'Auto ON/OFF', icon: PowerSettingsNew, path: '/settings/auto-on-off' },
        // { text: 'Control Mechanism', icon: SettingsIcon, path: '/settings/control-mechanism' }
    ];

    const accessItems = [
        { text: 'Share Access', icon: Share, path: '/settings/share-access' }
    ];

    const qwertyItems = [
        { text: 'Predictive Maintenance', icon: Build, path: '/settings/predictive-maintenance' },
        { text: 'Alerts and Notifications', icon: Notifications, path: '/settings/alerts-notifications' },
        { text: 'Enter Savings Goal', icon: Savings, path: '/settings/savings-goal' }
    ];

    return (
        <div className="settingsNavigationContainer">
            {/* <BreadcrumbsComponent breadcrumbs={breadcrumbs} /> */}
            <List className='settingsNavigation'>
                <CollapsibleSection title="Operations" items={operationsItems} setBreadcrumbs={setBreadcrumbs} activePath={activePath} setActivePath={setActivePath} />
                {/* <CollapsibleSection title="Configuration" items={configurationItems} setBreadcrumbs={setBreadcrumbs} activePath={activePath} setActivePath={setActivePath} /> */}
                {/* <CollapsibleSection title="Access" items={accessItems} setBreadcrumbs={setBreadcrumbs} activePath={activePath} setActivePath={setActivePath} /> */}
                {/* <CollapsibleSection title="Qwerty" items={qwertyItems} setBreadcrumbs={setBreadcrumbs} activePath={activePath} setActivePath={setActivePath} /> */}
            </List>
        </div>
    );
}

export default SettingsNavigation;
