import React from 'react'
import power from '../../assets/icon/powerIcon.png'

function ChartHeader({ name, subName,image,  textColor, iconBackgroundColor }) {
    const styles = {
        nameText: {
            color: "#363B3F",
            fontFamily: "manrope",
            fontSize: "17.666px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "0.53px",
        },
        subText: {
            color: textColor,
            fontFamily: "manrope",
            fontSize: "24.035px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "0.721px",
        },
        iconContainer: {
            height: '41px',
            width: '41px',
            borderRadius: "7px",
            background: iconBackgroundColor,
            padding: "5px",
        },
    }
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }
    function convertMsToTime(milliseconds) {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
      
        seconds = seconds % 60;
        minutes = minutes % 60;
      
        // 👇️ If you don't want to roll hours over, e.g. 24 to 00
        // 👇️ comment (or remove) the line below
        // commenting next line gets you `24:00:00` instead of `00:00:00`
        // or `36:15:31` instead of `12:15:31`, etc.
        hours = hours % 24;
      
        return `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m`;
      }
    return (
        <div style={{ display: "flex", justifyContent: 'space-between',height:'54px' }}>
            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                <div style={{ ...styles.nameText }}>{name}</div>
                <div style={{ ...styles.subText }}>{name==="On Time"?convertMsToTime(subName):subName}</div>
            </div>
            <div style={{
                ...styles.iconContainer
            }}>
              {/* <PowerIcon/> */}
              <img
                src={image}
                style={{maxHeight:'100%',maxWidth:'100%'}}
              />
            </div>
        </div>
    )
}

export default ChartHeader