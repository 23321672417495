
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function MoneySpentDetailContainer({ time, moneySpent }) {

  return (
    <DetailContainer
      name={"Total Money Spent"}
      value={`${moneySpent.toFixed(2)}`}
      time={time}
      color={"rgba(50, 47, 200, 0.10)"}
      borderColor={"#322FC8"}
      image={images.moneySpent}
    />
  )
}

export default MoneySpentDetailContainer