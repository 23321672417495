import { selectedHouseData, selectedRoomData, selectedRoomAllDevices, selectedDeviceData, clearDropdownData } from "../actionTypes"

export const dropdownSelectionData = (state = {
    houseId: '',
    roomId: '',
    devices: [],
    selectedDeviceDetails: {}
}, action) => {
    const { type, payload } = action

    switch (type) {
        case selectedHouseData.LOAD_SELECTED_HOUSE_DATA:
            return { ...state, houseId: payload }
        case selectedRoomData.LOAD_SELECTED_ROOM_DATA:
            return { ...state, roomId: payload }
        case selectedRoomAllDevices.LOAD_SELECTED_ROOM_DEVICES:
            return { ...state, devices: payload }
        case selectedDeviceData.LOAD_SELECTED_DEVICE_DATA:
            return { ...state, selectedDeviceDetails: payload }
        case clearDropdownData.CLEAR_DATA:
            return {
                roomId: '',
                devices: [],
                selectedDeviceDetails: {}
            }
        default:
            return state
    }
}