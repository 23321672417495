import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function TotalUnitsConsumedDetailContainer({ time, unitsConsumed }) {

  return (
    <DetailContainer
      name={"Total Units Consumed"}
      value={`${unitsConsumed?.toFixed(2)} kWh`}
      time={time}
      color={"rgba(254, 224, 55, 0.20)"}
      borderColor={"#FEE037"}
      image={
        images.unitConsumed !== undefined ? images.unitConsumed : 0
      }
    />
  )
}

export default TotalUnitsConsumedDetailContainer