import { collectiveData } from "../actionTypes"


export const loadMultipleDataReducer = (state=[],action)=>{
    const {type, payload} = action

    switch(type){
        case collectiveData.LOAD:
            return payload
        default :
            return state
    }

}