
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function MoneySavingDetailContainer({ time, moneySaved, moneySpent }) {



    return (
        <DetailContainer
            name={"Money Saving"}
            value={`${isNaN(moneySaved / (moneySpent + moneySaved)) ? "0" : ((moneySaved / (moneySpent + moneySaved)) * 100).toFixed(2)} %`}
            time={time}
            color={"rgba(50, 47, 200, 0.10)"}
            borderColor={"#322FC8"}
            image={images.coin !== undefined ? images.coin : 0}

        />
    )
}

export default MoneySavingDetailContainer