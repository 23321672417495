// import './App.css';
// import Login from './Login/Login';
// import MainPage from './component/MainPage/MainPage';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import {createBrowserRouter,RouterProvider} from 'react-router-dom'

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Login />,
//   },
//   {
//     path: "/dashboard",
//     element: <MainPage />,
//   },
//   {
//     path:"/home"
//   }
// ])
// function App() {
//   return (
//     <div className="App">
//      <RouterProvider router={router}/>    
//     </div>
//   );
// }

// export default App;

import './App.css';
import Login from './Login/Login';
import MainPage from './component/MainPage/MainPage';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useNavigate,createBrowserRouter,RouterProvider, Navigate } from 'react-router-dom';
import UpdateWifi from './component/Settings/SettingsNavigation/UpdateWifi/UpdateWifi';
import SettingsPage from './component/Settings/SettingsPage';
import ScheduleOperation from './component/Settings/SettingsNavigation/ScheduleOperation/ScheduleOperation';
import AutoOnOff from './component/Settings/SettingsNavigation/AutoOnOff/AutoOnOff';
import ControlMechanism from './component/Settings/SettingsNavigation/ControlMechanism/ControlMechanism';
import EditName from './component/Settings/SettingsNavigation/EditName/EditName';
import Report from './component/Settings/SettingsNavigation/Report/Report';
import SummaryPage from './component/Summary/Summary';
import DeviceStatus from './component/Settings/SettingsNavigation/DeviceStatus/DeviceStatus';

function AuthHandler() {
  const login = useSelector(state => state.login);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(login).length === 0) {
      navigate('/');
    } else {
      navigate('/dashboard');
    }
  }, [login, navigate]);

  return null;
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "dashboard",
    element: <MainPage />,
  },
  {
    path: "",
    element: <SettingsPage />,
    children: [
      { path: "schedules", element: <ScheduleOperation /> },
      { path: "reports", element: <Report/> },
      // {path:'deviceStaus', element:<DeviceStatus/>},
          // { path: "schedules", element: <Navigate to="schedule-operation" replace /> },
          { path: "update-wifi", element: <UpdateWifi /> },
          { path: "edit-names", element: <EditName /> },
          // { path: "schedule-operation", element: <div>Schedule Operation</div> },
          // { path: "schedule-operation", element: <ScheduleOperation /> },
          { path: "temperature-trigger", element: <div>Temperature Trigger</div> },
          // { path: "auto-on-off", element: <div>Auto ON/OFF</div> },
          { path: "auto-on-off", element: <div><AutoOnOff /></div> },
          // { path: "control-mechanism", element: <div>Control Mechanism</div> },
          { path: "control-mechanism", element: <ControlMechanism /> },
          { path: "share-access", element: <div>Share Access</div> },
          { path: "predictive-maintenance", element: <div>Predictive Maintenance</div> },
          { path: "alerts-notifications", element: <div>Alerts and Notifications</div> },
          { path: "savings-goal", element: <div>Enter Savings Goal</div> }
        ]
     
  },
  {
    path: "summary",
    element: <SummaryPage />,
  },
  // {
  //   path: "/home",
  //   element: <div>Home</div>
  // }
]);
function App() {
  return (
    <div className="App">
      <>
        <RouterProvider router={router} >
        <AuthHandler />
        </RouterProvider>
      </>
    </div>
  );
}

export default App;


