import store from "..";
import axios from "axios"
import { loginDetails } from "../actionTypes"
import { fetchAllHouses, fetchHouseDetails } from "./house";
import { selectedDay } from "./selectedDate";
import { fetchAndStoreMultipleDevicesTodayData } from "./totalData";
import { selectedHouse as selectedHouseFromDropdown, selectedRoom as selectedRoomFromDropdown, selectedRoomDevices, selectedDeviceFromDropdown } from '../../redux/action/deviceDropdownSelection';
import { config } from "../../../config";
import { environAwsApi, thermoAwsApi, weatherAwsApi } from "../../common/ApiCall";
export const loginDetail = (data) => {
    return {
        type: loginDetails.LOGINUSER,
        payload: data
    }
}

export const loginUserPhone = async (phoneNumber, password) => {
    try {
        const response = await axios.post(
            'https://subscriptioncloud.alistetechnologies.com/v2/auth/login',
            {
                mobile: `+91${phoneNumber}`,
                password,
                deviceToken: '',
            }
        );

        if (response.status === 200) {
            const { data } = response.data;

            const res = await fetchHouseDetails(data.profile.selectedHouse, phoneNumber);

            const houseData = res?.data

            await fetchAllHouses(phoneNumber)
            store.dispatch(loginDetail(data));

            const date = new Date().setHours(0, 0, 0, 0)
            store.dispatch(selectedDay(date))
            store.dispatch(loginDetail(data));
            // const houseData = store.getState().houseData;

            // if (Object.keys(houseData).length === 0) return;
            store.dispatch(selectedHouseFromDropdown(houseData._id))
   
            const roomsWithNonEmptyEnergySync = houseData?.rooms?.filter(room => room.energiSync.length > 0 || room.weatherSensors.length>0 || room.thermoSmarts.length>0 || room.environSyncs.length>0);
            console.log(roomsWithNonEmptyEnergySync);
     
            if (roomsWithNonEmptyEnergySync.length === 0) {
                return { success: true, message: 'Sign in successful' }
            }
            // setRoomCount(roomsWithNonEmptyEnergySync.length)
            const defaultRoom = roomsWithNonEmptyEnergySync[0]._id;
            const defaultRoomAllDevices = [...roomsWithNonEmptyEnergySync[0].energiSync.map((e)=>{
                return{...e,"type":config.deviceType.energiSync}
              }),...roomsWithNonEmptyEnergySync[0].thermoSmarts.map((e)=>{
                return{...e,"type":config.deviceType.thermoSmarts}
              }),...roomsWithNonEmptyEnergySync[0].weatherSensors.map((e)=>{
                return{...e,"type":config.deviceType.weatherSensors}
              }),...roomsWithNonEmptyEnergySync[0].environSyncs.map((e)=>{
                return{...e,"type":config.deviceType.environSyncs}
              })]

            const defaultDeviceDetails = defaultRoomAllDevices[0];

            store.dispatch(selectedRoomFromDropdown(defaultRoom));
            store.dispatch(selectedRoomDevices(defaultRoomAllDevices));
            store.dispatch(selectedDeviceFromDropdown(defaultDeviceDetails));
            const todayDateInStringWithoutHours = new Date().setHours(0, 0, 0, 0);

            try {
                if(defaultDeviceDetails.type===config.deviceType.energiSync){
                await fetchAndStoreMultipleDevicesTodayData([{ deviceId: defaultDeviceDetails.deviceId }]);
                }else if(defaultDeviceDetails.type===config.deviceType.environSyncs){
                    await environAwsApi(defaultDeviceDetails.deviceId,todayDateInStringWithoutHours)
                }else if(defaultDeviceDetails.type===config.deviceType.thermoSmarts){
                    await thermoAwsApi(defaultDeviceDetails.deviceId,todayDateInStringWithoutHours)
                }else if(defaultDeviceDetails.type===config.deviceType.weatherSensors){
                    await weatherAwsApi(defaultDeviceDetails.deviceId,todayDateInStringWithoutHours)
                }
            } catch (error) {
                console.error('Failed to fetch and store device data:', error);
            }

            return { success: true, message: 'Sign in successful' };
        } else {
            return { success: false, message: 'Authentication Error' };
        }
    } catch (error) {
        let message = error.message;

        if (error.response && error.response.data) {
            message = error.response.data.message;
        }

        return { success: false, message };
    }
};