import powerConsumption from '../../assets/icon/PowerConsumption.png'
import carbonFootPrint from '../../assets/icon/carbon.png'
import temperature from '../../assets/icon/temperature.png.png'
import onTime from '../../assets/icon/powerIcon.png'

import voltage from '../../assets/icon/voltage.png'
import powerFactor from '../../assets/icon/powerFactor.png'
import deviceStatus from '../../assets/icon/DeviceStatus.png'
import ac from '../../assets/icon/ac.png'
import unitConsumed from '../../assets/icon/unitConsume.png'
import moneySpent from '../../assets/icon/moneySpent.png'
import moneySaved from '../../assets/icon/moneySaved.png'
import applianceHealth from '../../assets/icon/ApplinaceHealth.png'
import savingPercantage from '../../assets/icon/saving.png'
import unit from '../../assets/icon/unit.png'
import coin from '../../assets/icon/coin.png'
import blub from '../../assets/icon/bulb.png'
import geyser from '../../assets/icon/geyser.png'
import smartService from '../../assets/icon/smartService.png'
import loginLogo from '../../assets/login/logo.png'
import centerBanner from '../../assets/login/loginBanner.webp'
import bottomBanner from '../../assets/login/bottomBanner.png'
// import ac from '../../assets/icon/ac.png'

import octiot from '../../assets/login/OCTIOT.png'
let test = false
export const octiotUrl = test?"http://localhost:3000":"https://savings.octiotenergy.com" 

export const images = {
    powerConsumption,
    carbonFootPrint,
    temperature,
    onTime,
    voltage,
    powerFactor,
    deviceStatus,
    ac,
    unitConsumed,
    moneySaved,
    moneySpent,
    applianceHealth,
    savingPercantage,
    unit,
    coin,
    blub,
    ac,
    geyser,
    smartService,
    loginLogo,
    centerBanner,
    bottomBanner,
    octiot
}