import { houseData, resetState } from "../actionTypes"

export const loadMultipleHouseData= (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case houseData.ADD:
            let data = { ...state }
            if (data[payload.id] === undefined) {
                data[payload.id] = {}
            }
            data[payload.id] = payload.house
            return data
            case resetState.RESET:
                return {};
        default:
            return state
    }
}