import { object } from 'prop-types';
import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";
import { carbonConsumed, carbonFootprintSaved, averagePower } from '../../common/function';
import ChartHeader from '../ChartHeader';
import { images } from '../../common/images';
import { totalCarbonConsume } from '../../common/util';

export default function MoneySavedBarChart({ carbonSavedData: barDatas, ppu }) {
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (barDatas.length === 0) return

        let categories = []
        let data = []
        for (let i of barDatas) {
            if (i !== undefined && Object.keys(i).length !== 0 && i.categories.length !== 0) {

                categories = i.categories

                data = barDatas.map((carbonData) => {
                    if (carbonData && carbonData.data) {
                        return {
                            ...carbonData,
                            data: carbonData.data.map((value) => value * 1.204 * ppu),


                        };
                    } else {
                        return carbonData;
                    }
                });
            }
        }
        setSeries(data)
        setOptions({
            colors: ['#1AC821'],
            chart: {
                stacked: true
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',

                    borderRadius: 5.556,
                    borderRadiusApplication: 'end',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                title: {
                    text: 'Rs'
                },
                labels: {
                    formatter: function (val) {
                        return `${(val).toFixed(5)} `
                    },
                },
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return `${(val).toFixed(5)} Rs`
                    }
                }
            }

        })
    }, [barDatas])

    return (
        <div>
            <ChartHeader
                // name={"Carbon Footprint Saved"}
                name={"Money Saved"}
                // subName={`${totalCarbonConsume(barDatas).toFixed(2)}  kg CO2`}
                // subName={`1 kg CO2`}
                image={images.moneySaved}
                textColor={"#1AC821"}
                iconBackgroundColor={"rgba(26, 200, 33, 0.10)"}
            />
            <ReactApexChart
                series={series}
                options={{ ...options, colors: series.length === 1 ? ['#1AC821'] : [] }}
                type="bar"
                height={215}
            />
        </div>
    )
}
