
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function ApplianceHealthDetailContainer({time,selectedDeviceSelectedDayData }) {

    return (
        <DetailContainer
        name={"Appliance Health"}
        // value={portelStatus.includes(login.userName) ? "Ok" : "Training"}
        value={"Good"}
        time={time}
        color={"rgba(254, 224, 55, 0.20)"}
        borderColor={"#FEE037"}
        image={images.applianceHealth}
      />
    )
}

export default ApplianceHealthDetailContainer