
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function TemperatureDetailContainer({ time, selectedDeviceSelectedDayData }) {
    console.log('====================================');
    console.log(selectedDeviceSelectedDayData,"temp");
    console.log('====================================');
    return (
        <DetailContainer
            name={"Temperature"}
            value={`${selectedDeviceSelectedDayData?.[0]?.temperatureLogs?.slice(-1)?.[0]?.t.toFixed(2) || 0}°C`}
            time={time}
            color={"#FDECEC"}
            borderColor={"#EF4444"}
            image={images.temperature}
        />
    )
}

export default TemperatureDetailContainer