
import React, { useEffect, useState } from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function WeattherValue({ time, selectedDeviceSelectedDayData,dataKey,valueKey,name,timeKey,degree="°C" }) {
    let [value,setValue] = useState([])
     useEffect(()=>{
       let value= ((selectedDeviceSelectedDayData?.[0][dataKey])===undefined || (selectedDeviceSelectedDayData?.[0][dataKey]).length===0)?[]:selectedDeviceSelectedDayData?.[0][dataKey].sort(
            (a, b) => parseFloat(a[timeKey]) - parseFloat(b[timeKey])
          )?.slice(-1)
          setValue(value)
     },[selectedDeviceSelectedDayData])
    
     console.log(value,timeKey,'vl',valueKey,selectedDeviceSelectedDayData);
    return (
        <DetailContainer
            name={name}
            value={`${value.length>0?value[0][valueKey].toFixed(2):0}${degree}`}
            time={`${value.length>0?new Date(value[0][timeKey]):0}`}
            color={"#FDECEC"}
            borderColor={"#EF4444"}g
            image={images.temperature}
        />
    )
}

export default WeattherValue