import store from "..";
import axios from "axios"
import { housesList } from "../actionTypes"

export const housesListAction = (data) => {
    return {
        type: housesList.LOADHOUSE_DATA,
        payload: data
    }
}
