
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function VoltageDetailContainer({ phaseLoadLogs, phaseWiseData, phase, deviceId }) {
  
  // Find the last valid log that doesn't have the 'ct' key which means that log will be of 'disconnect' type
  const validPhaseData = phaseWiseData.filter(log => !log.hasOwnProperty('ct'));

  // Check if there is a valid log to display
  const lastValidLog = validPhaseData.length > 0 ? validPhaseData[validPhaseData.length - 1] : null;

  return (
    <DetailContainer
      name={`Voltage ${Object.keys(phaseLoadLogs[deviceId]).length > 1 ? Number(phase) + 1 : ''}`}
      value={`${(Object.keys(phaseLoadLogs)?.length === 1 &&
        lastValidLog
        ? lastValidLog.v
        : 0
      ).toFixed(2)} V`}
      time={
        Object.keys(phaseLoadLogs)?.length !== 0 &&
          phaseWiseData.length !== 0
          ? phaseWiseData[phaseWiseData.length - 1].ts
          : 0
      }
      // time={
      //   Object.keys(phaseLoadLogs)?.length !== 0 &&
      //     lastValidLog
      //     ? lastValidLog.ts
      //     : 0
      // }
      color={"#FDECEC"}
      borderColor={"#EF4444"}
      image={images.voltage}
    />
  );
}


export default VoltageDetailContainer