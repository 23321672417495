import { reportTypes, resetState } from "../actionTypes";

const initialState = {
    reports: {}
};

const unitReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case reportTypes.ADD_UNIT:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    [action.payload.reportId]: {...state.reports[action.payload.reportId],...action.payload.data}
                }
            };
            case reportTypes.UPDATE_CALL_COUNT:
                return {
                    ...state,
                    reports: {
                        ...state.reports,
                        [action.payload.reportId]: {...state.reports[action.payload.reportId],currentCall:state.reports[action.payload.reportId].currentCall+1}
                    }
                };   
        case resetState.RESET:
            return initialState;
        default:
            return state;
    }
};

export default unitReportReducer;