import { Box, Button } from "@mui/material";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import Dropdown from "rsuite/Dropdown";
import "rsuite/Dropdown/styles/index.css";
import { notShowSavingReportByHouseName, reportType } from "./data";
import "./Report.css";
import { useSelector } from "react-redux";
import { messages } from "../../../common/notification";
import {
  calculateUnitReport,
  loadLogsReport,
  savingReport,
  temperatureReport,
} from "./function";
import { Spinner, Table } from "react-bootstrap";
import moment from "moment";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { fetchHouseDetails } from "../../../redux/action/house";
import store from "../../../redux";
import { pages } from "../../../../ApiRoute";

function Report() {
  const { afterToday } = DateRangePicker;
  const unitReport = useSelector((state) => state.unitReport);
  // const houseData = useSelector(state => state.houseData);

  const housesList = useSelector((state) => state.housesList);
  const mutipleHouse = useSelector((state) => state.mutipleHouse);
  const [energiSync, setEnergiSync] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedReportType, setSelectedReportType] = useState({
    value: "Select",
    name: "Select",
  });
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedHouse, setSelectedHouse] = useState([]);
  const [houseData, setHouseData] = useState({});

  const handleDateChange = (range) => {
    if (range) {
      const [startDate, endDate] = range;
      setDateRange({ startDate: startDate, endDate: endDate });
      console.log("Selected Range:", startDate, endDate);
    }
  };

  useEffect(() => {
    let totalEnergy = [];
    if (Object.keys(mutipleHouse).length > 0 && selectedHouse.length>0) {
      for(let house of selectedHouse){
        if(mutipleHouse[house.value]===undefined) continue
        let houseData = mutipleHouse[house.value]
        if (houseData.rooms.length > 0) {
          for (let room of houseData.rooms) {
            if (room.energiSync.length > 0) {
              for (let device of room.energiSync) {
                totalEnergy.push({ roomName: room.roomName, ...device,houseName:houseData.houseName,houseId:houseData._id });
              }
            }
          }
        }
      }
     
    }

    setEnergiSync(totalEnergy);
  }, [mutipleHouse,selectedHouse]);
   // Handler to toggle select all
   const handleChange = (selected) => {
    let options =housesList.map((e) => {
      return {
        value: e.houseAccessCode,
        label: e.houseName,
      };
    })
    // Check if "Select All" option was clicked
    if (selected && selected.some((option) => option.value === "select_all")) {
      // Select All functionality
      setSelectedHouse(
        selected.length === options.length + 1 ? [] : [...options]
      );
    } else {
      setSelectedHouse(selected);
    }
  };
  const handleChangeEnergyDevice = (selected) => {
    let options = energiSync.map((e) => {
      return {
        ...e,
        value: e.deviceId,
        label: `${
          e.name !== undefined && e.name !== ""
            ? `${e.name}-${e.deviceId}`
            : e.deviceId
        }`,
      };
    })
    // Check if "Select All" option was clicked
    if (selected && selected.some((option) => option.value === "select_all")) {
      // Select All functionality
      setSelectedDevices(
        selected.length === options.length + 1 ? [] : [...options]
      );
    } else {
      setSelectedDevices(selected);
    }
  };
  useEffect(()=>{
    const loginDetails = store.getState().login;

    const mobileWithoutCountryCode =
      loginDetails?.profile?.mobile.slice(3);
    for(let i of selectedHouse){
      fetchHouseDetails(
        i.value,
        mobileWithoutCountryCode,
        pages.schedulePage
      );}
  },[selectedHouse])
  return (
    <Box>
      <Box className="d-flex text-start align-items-end gap-5">
        <Box sx={{minWidth:'200px'}}>
          <DemoItem className="text-start">Property</DemoItem>
          <Select
              className="react-select-container-house-report"
              classNamePrefix="react-select-house-report"
            style={{ width: "100% !important" }}
            options={[{ value: "select_all", label: "Select All" },...housesList.map((e) => {
              return {
                value: e.houseAccessCode,
                label: e.houseName,
              };
            })]}
            closeMenuOnSelect={false}
            isMulti
            value={selectedHouse}
            onChange={(data) => {
             
                
              handleChange(data)
            }}
          />
        </Box>
        <Box>
          <DemoItem className="text-start">Select Date</DemoItem>
          <DateRangePicker
            shouldDisableDate={afterToday()} // Disable future dates
            onChange={handleDateChange} // Get selected date range
            placeholder="Select Date Range"
          />
        </Box>
        <Box sx={{ minWidth: "150px" }}>
          <DemoItem className="text-start">Report Type</DemoItem>
          <Dropdown
            title={selectedReportType.name}
            style={{ minWidth: "150px" }}
          >
            {reportType
              .filter((rep) => {
                if (
                  notShowSavingReportByHouseName.includes(houseData.houseName)
                ) {
                  if (rep.name !== reportType[4].name) {
                    return rep;
                  }
                } else {
                  return rep;
                }
              })
              .map((r) => {
                return (
                  <Dropdown.Item onClick={() => setSelectedReportType(r)}>
                    {r.name}
                  </Dropdown.Item>
                );
              })}
          </Dropdown>
        </Box>
        {selectedReportType.name === reportType[3].name && (
          <Box>
            <DemoItem className="text-start">Select Equipment</DemoItem>
            <Select
              className="d-flex react-select-container-house-report"
              closeMenuOnSelect={false}
              isMulti

              classNamePrefix="react-select-house-report"
              options={[{ value: "select_all", label: "Select All" },...energiSync.map((e) => {
                return {
                  ...e,
                  value: e.deviceId,
                  label: `${
                    e.name !== undefined && e.name !== ""
                      ? `${e.name}-${e.deviceId}`
                      : e.deviceId
                  }`,
                };
              })]}
              value={selectedDevices}
              onChange={(data) => {
                handleChangeEnergyDevice(data)
              }}
            />
          </Box>
        )}

        <Box>
          <Button
            variant="contained"
            onClick={() => {
              if (
                selectedReportType.value === "Select" ||
                dateRange.startDate === null ||
                dateRange.endDate === null
              ) {
                messages.error("Select All Values");
                return;
              }
              if (energiSync.length === 0) {
                messages.error("There is no devices in selected house");
                return;
              }
              if (selectedReportType.value === reportType[0].value) {
                calculateUnitReport(dateRange, energiSync, selectedReportType,selectedHouse);
              }
              if (selectedReportType.value === reportType[2].value) {
                temperatureReport(dateRange, energiSync,selectedHouse);
              }

              if (selectedReportType.value === reportType[3].value) {
                if (selectedDevices.length === 0) {
                  messages.error("Equipment not selected");
                  return;
                }
                loadLogsReport(dateRange, selectedDevices,selectedHouse);
              }
              if (selectedReportType.value === reportType[4].value) {
                savingReport(dateRange, energiSync,selectedHouse);
              }
            }}
          >
            Get Report
          </Button>
        </Box>
      </Box>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Date Range</th>
            <th>Type</th>
            <th>Percentage</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(unitReport.reports).map((id, index) => {
            let date = unitReport.reports[id].date;
            return (
              <tr key={id}>
                <td>{index + 1}</td>
                <td>{`${moment(date.startDate).format("DD-MM-YYYY")} - ${moment(
                  date.endDate
                ).format("DD-MM-YYYY")}`}</td>
                <td>{unitReport.reports[id].type}</td>
                <td>{
                  ((unitReport.reports[id]?.currentCall/unitReport.reports[id]?.totalCalls)*100).toFixed(0)
                  }%</td>
                <td>
                  {unitReport.reports[id].data.length > 0 &&
                  unitReport.reports[id].success === true ? (
                    <button className="btn btn-primary">
                      <CSVLink
                        data={unitReport.reports[id].data}
                        filename={`${moment(date.startDate).format(
                          "DD-MM-YYYY"
                        )} - ${moment(date.endDate).format("DD-MM-YYYY")}_${
                          unitReport.reports[id].type
                        }.csv`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {" "}
                        Download
                      </CSVLink>
                    </button>
                  ) : unitReport.reports[id].data.length === 0 &&
                    unitReport.reports[id].success === true ? (
                    <button className="btn btn-primary" disabled>
                      Data Not Available
                    </button>
                  ) : unitReport.reports[id].data.length === 0 &&
                    unitReport.reports[id].success === false ? (
                    <button className="btn btn-danger">Failed</button>
                  ) : (
                    <button className="btn btn-primary" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Box className="mt-5" style={{ height: "20px" }}></Box>
    </Box>
  );
}

export default Report;
