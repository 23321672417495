import { housesList } from "../actionTypes"

export const housesListReducer = (state=[],action)=>{
    const {type,payload} = action
    switch(type){
        case housesList.LOADHOUSE_DATA:
            return payload
        default :
           return state
    }
}