import React, { useState } from 'react';
import { Container, Grid, Paper, Checkbox, IconButton, Typography, Box, Drawer, Button, TextField, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MoreVert, ArrowBack, AccessTime } from '@mui/icons-material';

const devices = [
  { id: 1, serial: '01', name: 'XYZ', room: 'Living room', startTime: '10:00 AM', endTime: '9:00 PM', onTime: '30 mins', offTime: '5 mins', status: true },
  { id: 2, serial: '02', name: 'XYZ', room: 'Living room', startTime: '10:00 AM', endTime: '9:00 PM', onTime: '30 mins', offTime: '5 mins', status: true },
  { id: 3, serial: '03', name: 'XYZ', room: 'Living room', startTime: '10:00 AM', endTime: '9:00 PM', onTime: '30 mins', offTime: '5 mins', status: true },
  { id: 4, serial: '04', name: 'XYZ', room: 'Living room', startTime: '10:00 AM', endTime: '9:00 PM', onTime: '30 mins', offTime: '5 mins', status: true },
  { id: 5, serial: '05', name: 'XYZ', room: 'Living room', startTime: '10:00 AM', endTime: '9:00 PM', onTime: '30 mins', offTime: '5 mins', status: true },
];

const AutoOnOff = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [runTiming, setRunTiming] = useState('always');

  const handleThreeDotsClick = (device) => {
    setSelectedDevice(device);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedDevice(null);
  };

  const handleRunTimingChange = (event, newRunTiming) => {
    if (newRunTiming !== null) {
      setRunTiming(newRunTiming);
    }
  };

  return (
    <Container maxWidth="xl" style={{ padding: '0px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: '16px', backgroundColor: '#f8f9fa' }}>
            <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" alignItems="center">
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>#</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>SNo</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>Name</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>Room</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>Start Time</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>End Time</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>On Time</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>Off Time</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>Status</Typography>
              <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>Action</Typography>
            </Box>
          </Paper>
        </Grid>
        {devices.map((device) => (
          <Grid item xs={12} key={device.id}>
            <Paper style={{ padding: '16px' }}>
              <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" alignItems="center">
                <Checkbox />
                <Typography variant="body1" style={{ textAlign: 'center' }}>{device.serial}</Typography>
                <Typography variant="body1" style={{ textAlign: 'center' }}>{device.name}</Typography>
                <Typography variant="body1" style={{ textAlign: 'center' }}>{device.room}</Typography>
                <Typography variant="body1" style={{ textAlign: 'center' }}>{device.startTime}</Typography>
                <Typography variant="body1" style={{ textAlign: 'center' }}>{device.endTime}</Typography>
                <Typography variant="body1" style={{ textAlign: 'center' }}>{device.onTime}</Typography>
                <Typography variant="body1" style={{ textAlign: 'center' }}>{device.offTime}</Typography>
                <Box display="flex" justifyContent="center">
                  <Switch checked={device.status} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <IconButton onClick={() => handleThreeDotsClick(device)}><MoreVert /></IconButton>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer} PaperProps={{ style: { width: '33%' } }}>
        <Box style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
          <IconButton onClick={handleCloseDrawer}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" style={{ marginLeft: '7%', fontWeight: 'bold' }}>Edit Auto On/Off Parameters</Typography>
        </Box>
        <Box style={{ padding: '0 16%', height: 'calc(100% - 70px)', overflowY: 'auto' }}>
          <Typography variant="body2" color="textSecondary">
            Set up your energy savings system by fixing the frequency of regulating your appliances operation.
            <Box my={2} />
            For example, you can set your AC to turn off for 5 minutes every 30 minutes during the night: Thereby reducing your power consumption by 17%
          </Typography>
          {selectedDevice && (
            <Box>
              <TextField
                label="Name"
                placeholder="Write Name"
                fullWidth
                margin="normal"
              />
              <Typography variant="body2" color="textSecondary" style={{ marginTop: '16px' }}>Run Timings</Typography>
              <ToggleButtonGroup
                value={runTiming}
                exclusive
                onChange={handleRunTimingChange}
                fullWidth
                style={{ marginTop: '8px' }}
              >
                <ToggleButton value="always" style={{ flex: 1 }}>Always</ToggleButton>
                <ToggleButton value="particularTime" style={{ flex: 1 }}>Particular Time</ToggleButton>
              </ToggleButtonGroup>
              {runTiming === 'particularTime' && (
                <Box style={{ marginTop: '16px' }}>
                  <TextField
                    label="Start Time"
                    type="time"
                    defaultValue="10:00"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }}
                    margin="normal"
                    InputProps={{
                      endAdornment: <AccessTime />,
                    }}
                  />
                  <TextField
                    label="End Time"
                    type="time"
                    defaultValue="13:00"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }}
                    margin="normal"
                    InputProps={{
                      endAdornment: <AccessTime />,
                    }}
                  />
                </Box>
              )}
              <Typography variant="body2" color="textSecondary" style={{ marginTop: '16px' }}>Run Duration</Typography>
              <Box style={{ display: 'flex', marginTop: '8px' }}>
                <TextField
                  label="On Time"
                  defaultValue="30 mins"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  InputProps={{
                    endAdornment: <AccessTime />,
                  }}
                />
                <Box style={{ width: '16px' }} />
                <TextField
                  label="Off Time"
                  defaultValue="5 mins"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  InputProps={{
                    endAdornment: <AccessTime />,
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '16px' }}
              >
                Select Appliances
              </Button>
              <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCloseDrawer}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </Container>
  );
};

export default AutoOnOff;
