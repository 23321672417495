import React from 'react';
import { Container, Grid, Paper, Checkbox, IconButton, Typography, Box } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const tableData = [
  { id: 1, serial: '01', loadType: 'Power (Load Connected to device directly)', controlType: 'Relay (on/off judged based on relay)', onThreshold: '0', feedbackOnTimeout: '30', feedbackOffTimeout: '30', retryConfigOn: '1', retryConfigOnTime: '01 hr : 24 min : 34 sec', retryConfigOff: '1', retryConfigOffTime: '01 hr : 24 min : 34 sec' },
  { id: 2, serial: '01', loadType: 'Power (Load Connected to device directly)', controlType: 'Relay (on/off judged based on relay)', onThreshold: '0', feedbackOnTimeout: '30', feedbackOffTimeout: '30', retryConfigOn: '1', retryConfigOnTime: '01 hr : 24 min : 34 sec', retryConfigOff: '1', retryConfigOffTime: '01 hr : 24 min : 34 sec' },
  { id: 3, serial: '01', loadType: 'Power (Load Connected to device directly)', controlType: 'Relay (on/off judged based on relay)', onThreshold: '0', feedbackOnTimeout: '30', feedbackOffTimeout: '30', retryConfigOn: '1', retryConfigOnTime: '01 hr : 24 min : 34 sec', retryConfigOff: '1', retryConfigOffTime: '01 hr : 24 min : 34 sec' },
  { id: 4, serial: '01', loadType: 'Power (Load Connected to device directly)', controlType: 'Relay (on/off judged based on relay)', onThreshold: '0', feedbackOnTimeout: '30', feedbackOffTimeout: '30', retryConfigOn: '1', retryConfigOnTime: '01 hr : 24 min : 34 sec', retryConfigOff: '1', retryConfigOffTime: '01 hr : 24 min : 34 sec' },
  { id: 5, serial: '01', loadType: 'Power (Load Connected to device directly)', controlType: 'Relay (on/off judged based on relay)', onThreshold: '0', feedbackOnTimeout: '30', feedbackOffTimeout: '30', retryConfigOn: '1', retryConfigOnTime: '01 hr : 24 min : 34 sec', retryConfigOff: '1', retryConfigOffTime: '01 hr : 24 min : 34 sec' },
];

const ControlMechanism = () => {
  const headerStyle = { textAlign: 'center', fontWeight: 'bold' };
  const cellStyle = { textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' };

  return (
    <Container maxWidth="xl" style={{ padding: '0px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: '16px', display: 'flex', alignItems: 'center', backgroundColor: '#f8f9fa' }}>
            <Box style={{ flex: 1 }}></Box>
            <Typography variant="body1" style={{ ...headerStyle, flex: 1 }}>S. no.</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Load Type</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Control Type</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 1 }}>On Threshold</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Feedback On timeout</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Feedback Off timeout</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Retry configuration On</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Retry configuration On Time</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Retry configuration Off</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Retry configuration Off Time</Typography>
            <Box style={{ flex: 1 }}></Box>
          </Paper>
        </Grid>
        {tableData.map((row) => (
          <Grid item xs={12} key={row.id}>
            <Paper style={{ padding: '16px', display: 'flex', alignItems: 'center' }}>
              <Box style={{ flex: 1 }}>
                <Checkbox />
              </Box>
              <Typography variant="body1" style={{ ...cellStyle, flex: 1 }}>{row.serial}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{row.loadType}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{row.controlType}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 1 }}>{row.onThreshold}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{row.feedbackOnTimeout}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{row.feedbackOffTimeout}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{row.retryConfigOn}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{row.retryConfigOnTime}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{row.retryConfigOff}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{row.retryConfigOffTime}</Typography>
              <Box style={{ flex: 1 }}>
                <IconButton><MoreVert /></IconButton>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ControlMechanism;
