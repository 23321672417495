import React from 'react'
import { decimalToBinary } from './common/function'
import { images } from './common/images';
import DetailContainer from './MainPage/common/DetailContainer';

function ThermoSmartStateSystemValue({time, selectedDeviceSelectedDayData,dataKey,valueKey,name,timeKey,degree="°C" }) {

  let binaryNumber=decimalToBinary(((selectedDeviceSelectedDayData?.[0][dataKey])===undefined || (selectedDeviceSelectedDayData?.[0][dataKey]).length===0)?0:selectedDeviceSelectedDayData?.[0][dataKey].sort(
    (a, b) => parseFloat(a[timeKey]) - parseFloat(b[timeKey])
  )?.slice(-1)?.[0][valueKey]) || '00000'
 
  const components = [
    "CP2",
    "CP1",
    "HF",
    "MF",
    "LF"
    ]
    
  return (
    <>
    {
      binaryNumber.split('').map((val,Index)=>{
        return   <DetailContainer
        name={components[Index]}
        value={`${val==='1'?'On':'Off'}`}
        time={time}
        color={"#FDECEC"}
        borderColor={"#EF4444"}
        image={images.temperature}
    />
      })
    }
   
    </>
  )
}

export default ThermoSmartStateSystemValue