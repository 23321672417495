
import React, { useEffect, useState } from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';
import { totalCarbonConsume } from '../common/util';
import {
  averagePower,
  carbonConsumed,
  carbonFootprintSaved,
  moneySavedSmartOffSessions,
  powerConsumption,
  temperatureData,
  currentData,
  voltageData
} from "../common/function";

function CarbonSavedDetailContainer({ time, carbonSavedData, carbonConsumedData }) {
  const hasCarbonSavedData = Object.keys(carbonSavedData).length !== 0;
  const totalCarbonConsumeValue = totalCarbonConsume(carbonConsumedData);
  const totalCarbonSavedValue = totalCarbonConsume(carbonSavedData);
  const carbonConsumePercentage = hasCarbonSavedData
    ? ((totalCarbonSavedValue / (totalCarbonConsumeValue + totalCarbonSavedValue)) * 100).toFixed(2)
    : 0;

  return (
    <DetailContainer
      name={"Carbon Saving"}
      value={`${!isNaN(carbonConsumePercentage) ? carbonConsumePercentage : "0"} %`}
      time={time}
      color={"rgba(26, 200, 33, 0.10)"}
      borderColor={"#322FC8"}
      image={images.carbonFootPrint !== undefined ? images.carbonFootPrint : 0}
    />
  )
}

export default CarbonSavedDetailContainer