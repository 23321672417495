import React, { useEffect, useState } from 'react'
import { Container, Form, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'
import { useDispatch } from 'react-redux';
import { loginDetail } from '../component/redux/action/login';
import { fetchSingleDeviceTodayData } from '../component/redux/action/TodayData(NotInUse)';
import { messages } from '../component/common/notification';
import { Toaster } from 'react-hot-toast';
import smardenLogo from '../assets/SmarDen Logo.png'
import { images, octiotUrl } from '../component/common/images';
import { useSelector } from "react-redux";
// import { common } from '@mui/material/colors';
import { loginUserPhone } from '../component/redux/action/login';
export const user = [
  {
    userName: 'Admin',
    password: 'admin',
    deviceId: [
      { deviceId: 'M240003', name: 'M240003' },
      { deviceId: 'M240001', name: 'M240001' },
    ]
  },
  {
    userName: 'habitat',
    password: 'password',
    deviceId: [
      { deviceId: 'M250001', name: 'M250001', image: images.ac, showName: 'Outdoor AC' },
      { deviceId: 'M240002', name: 'M240002', image: images.ac, showName: 'Common Area Ac' },
      { deviceId: 'M280024', name: 'M280024', image: images.ac, showName: 'Cabin AC' },
    ],

  },
  {
    userName: 'wiley',
    password: 'password',
    cpu: 9.5,
    deviceId: [
      { deviceId: 'M240007', name: 'M240007' },
      { deviceId: 'M240008', name: 'M240008' },
    ]
  },
  {
    userName: 'abl',
    password: 'password',
    deviceId: [
      { deviceId: 'M240009', name: 'M240009' },
    ]
  },
  {
    userName: 'smarden',
    password: 'password',
    deviceId: [
      { deviceId: 'M240021', name: 'M240021' },
    ],
    logo: smardenLogo,
    style: { width: '110.304px' }
  },
  {
    userName: 'aliste',
    password: 'aliste',
    deviceId: [
      { deviceId: 'M240014', name: "M240014" },
      { deviceId: 'M240011', name: "M240011" },
      { deviceId: 'M240015', name: "M240015" },
      { deviceId: 'M240016', name: "M240016" },
      { deviceId: 'M240003', name: "M240003" },
      { deviceId: 'M240001', name: "M240001" },
      { deviceId: 'M240002', name: "M240002" },
      { deviceId: 'M250001', name: "M250001" },
      { deviceId: 'M240007', name: "M240007" },
      { deviceId: 'M240008', name: "M240008" },
      { deviceId: 'M240009', name: "M240009" },
      { deviceId: 'M240017', name: "M240017" },]
  },
  {
    userName: 'laffaire',
    password: 'password',
    deviceId: [
      { deviceId: 'M240030', name: "101", image: images.ac, showName: '101' },
      { deviceId: 'M240016', name: "102", image: images.ac, showName: '102' },
      { deviceId: 'M240052', name: "103", image: images.ac, showName: '103' },
      { deviceId: 'M240017', name: "104", image: images.ac, showName: '104' },
      { deviceId: 'M240022', name: "105", image: images.ac, showName: '105' },
      { deviceId: 'M240013', name: "106", image: images.ac, showName: '106' },
      { deviceId: 'M240050', name: "107", image: images.ac, showName: '107' },
      { deviceId: 'M240024', name: "108", image: images.ac, showName: '108' },
      { deviceId: 'M240014', name: '109', image: images.ac, showName: '109' },
      { deviceId: 'M240035', name: "201", image: images.ac, showName: '201' },
      { deviceId: 'M240020', name: "202", image: images.ac, showName: '202' },
      { deviceId: 'M240041', name: "203", image: images.ac, showName: '203' },
      { deviceId: 'M240036', name: "204", image: images.ac, showName: '204' },
      { deviceId: 'M240049', name: "205", image: images.ac, showName: '205' },
      { deviceId: 'M240011', name: "206", image: images.ac, showName: '206' },
      { deviceId: 'M240033', name: "207", image: images.ac, showName: '207' },
      { deviceId: 'M240027', name: "208", image: images.ac, showName: '208' },
      { deviceId: 'M240045', name: "209", image: images.ac, showName: '209' },
      { deviceId: 'M240047', name: "210", image: images.ac, showName: '210' },
      { deviceId: 'M240055', name: "301", image: images.ac, showName: '301' },
      { deviceId: 'M240037', name: "302", image: images.ac, showName: '302' },
      { deviceId: 'M240042', name: "303", image: images.ac, showName: '303' },
      { deviceId: 'M240056', name: "304", image: images.ac, showName: '304' },
      { deviceId: 'M240058', name: "305", image: images.ac, showName: '305' },
      { deviceId: 'M240044', name: "306", image: images.ac, showName: '306' },
      { deviceId: 'M240054', name: "307", image: images.ac, showName: '307' },
      { deviceId: 'M240040', name: "308", image: images.ac, showName: '308' },
      { deviceId: 'M240010', name: "309", image: images.ac, showName: '309' },
      { deviceId: 'M240015', name: "310", image: images.ac, showName: '310' },
    ]
  },
  {
    userName: 'yourspace',
    password: 'password',
    deviceId: [
      { deviceId: 'M240026', name: "M240026", image: images.ac, showName: 'Common AC' },
      { deviceId: 'M280014', name: "M280014", image: images.geyser, showName: 'Geyser 1', metered: false },
      { deviceId: 'M280011', name: "M280011", image: images.geyser, showName: 'Geyser 2', metered: false },
      { deviceId: 'M280010', name: "M280010", image: images.ac, showName: 'AC 1', metered: false },
      { deviceId: 'M280013', name: "M280013", image: images.ac, showName: 'AC 2', metered: false },
    ]
  },
  {
    userName: 'tribespace',
    password: 'password',
    deviceId: [
      { deviceId: 'M240019', name: "M240019" },
      { deviceId: 'M240020', name: "M240020" },
    ]
  },
  {
    userName: 'campus',
    password: 'password',
    deviceId: [
      { deviceId: 'M240025', name: "Light", image: images.blub, showName: 'Light' },
      { deviceId: 'M240028', name: "AC", image: images.ac, showName: 'AC' },
      { deviceId: 'M240046', name: "AC", image: images.ac, showName: 'AC' },
    ]
  },
  {
    userName: 'oceans',
    password: 'password',
    cpu: 9.5,
    deviceId: [
      { deviceId: 'M240063', name: "M240063" },
      { deviceId: 'M240064', name: "M240064" },
    ]
  },
  {
    userName: 'vietnom',
    password: 'password',
    deviceId: [
      { deviceId: 'M250006', name: "M250006" },
      { deviceId: 'M250005', name: "M250005" },
      { deviceId: 'M250010', name: "M250010" },
    ]
  },
  {
    userName: 'paras',
    password: 'password',
    deviceId: [
      { deviceId: 'M280029', name: "M280029", image: images.ac, showName: 'Room - 307' },
      { deviceId: 'M280022', name: "M280022", image: images.ac, showName: 'Room - 202' },
    ]
  },
  {
    userName: 'bata',
    password: 'password',
    cpu: 6.4,
    deviceId: [
      { deviceId: 'M250008', name: "M250008" },
    ]
  },
  {
    userName: 'grandcentral',
    password: 'password',
    deviceId: [

      { deviceId: 'M280043', name: "101", image: images.ac, showName: 'Room - 101' },
      { deviceId: 'M280035', name: "102", image: images.ac, showName: 'Room - 102' },
      { deviceId: 'M280021', name: "103", image: images.ac, showName: 'Room - 103' },
      { deviceId: 'M280038', name: "104", image: images.ac, showName: 'Room - 104' },
      { deviceId: 'M280037', name: "105", image: images.ac, showName: 'Room - 105' },
      { deviceId: 'M280036', name: "106", image: images.ac, showName: 'Room - 106' },
      { deviceId: 'M280020', name: "107", image: images.ac, showName: 'Room - 107' },
      { deviceId: 'M280044', name: "108", image: images.ac, showName: 'Room - 108' },
      { deviceId: 'M280030', name: "201", image: images.ac, showName: 'Room - 201' },
      { deviceId: 'M280027', name: "202", image: images.ac, showName: 'Room - 202' },
      { deviceId: 'M280016', name: "203", image: images.ac, showName: 'Room - 203' },
      { deviceId: 'M280017', name: "204", image: images.ac, showName: 'Room - 204' },
      { deviceId: 'M280042', name: "205", image: images.ac, showName: 'Room - 205' },
      { deviceId: 'M280003', name: "206", image: images.ac, showName: 'Room - 206' },
      { deviceId: 'M280032', name: "207", image: images.ac, showName: 'Room - 207' },
      { deviceId: 'M280028', name: "208", image: images.ac, showName: 'Room - 208' },
      { deviceId: 'M280059', name: "209", image: images.ac, showName: 'Room - 209' },
      { deviceId: 'M280025', name: "301", image: images.ac, showName: 'Room - 301' },
      { deviceId: 'M280039', name: "302", image: images.ac, showName: 'Room - 302' },
      { deviceId: 'M280031', name: "303", image: images.ac, showName: 'Room - 303' },
      { deviceId: 'M280023', name: "304", image: images.ac, showName: 'Room - 304' },
      { deviceId: 'M280041', name: "305", image: images.ac, showName: 'Room - 305' },
      { deviceId: 'M280033', name: "306", image: images.ac, showName: 'Room - 306' },
      { deviceId: 'M280005', name: "307", image: images.ac, showName: 'Room - 307' },
      { deviceId: 'M280040', name: "308", image: images.ac, showName: 'Room - 308' },
      { deviceId: 'M280006', name: "309", image: images.ac, showName: 'Room - 309' },
    ]
  },
  {
    userName: 'steelo',
    password: 'password',
    deviceId: [
      { deviceId: 'M240115', name: "M240115" },
    ]
  },
  {
    userName: 'sarthakpalace',
    password: 'password',
    deviceId: [
      { deviceId: 'M240097', name: "103" },
      { deviceId: 'M240114', name: "107" },
    ]
  },
  {
    userName: 'alankaram',
    password: 'password',
    deviceId: [
      { deviceId: 'M280048', name: "M280048" },
      { deviceId: 'M280069', name: "M280069" },
      { deviceId: 'M280063', name: "M280063" },
      { deviceId: 'M280052', name: "M280052" },
      { deviceId: 'M280066', name: "M280066" },
      { deviceId: 'M280046', name: "M280046" },
      { deviceId: 'M280067', name: "M280067" },
      { deviceId: 'M280064', name: "M280064" },
      { deviceId: 'M280056', name: "M280056" },
      { deviceId: 'M280058', name: "M280058" },
      { deviceId: 'M280057', name: "M280057" },
      { deviceId: 'M280055', name: "M280055" },
      { deviceId: 'M280060', name: "M280060" },
      { deviceId: 'M280049', name: "M280049" },
      { deviceId: 'M280054', name: "M280054" },
      { deviceId: 'M280047', name: "M280047" },
      { deviceId: 'M280053', name: "M280053" },
      { deviceId: 'M280045', name: "M280045" },
      { deviceId: 'M280050', name: "M280050" },
      { deviceId: 'M280062', name: "M280062" },
      { deviceId: 'M280065', name: "M280065" },
      { deviceId: 'M280059', name: "M280059" },
      { deviceId: 'M2400443', name: "M240043" },
    ]
  },
  {
    userName: 'ARS',
    password: 'password',
    deviceId: [
      { deviceId: 'M240071', name: "M240071" },
    ]
  },
  {
    userName: 'campus2',
    password: 'password',
    deviceId: [
      { deviceId: 'M240107', name: "AC 1", image: images.ac, showName: 'AC 1' },
      { deviceId: 'M240106', name: "AC 2", image: images.ac, showName: 'AC 2' },
      { deviceId: 'M240120', name: 'Light', image: images.blub, showName: 'Light' }
    ]
  },
  {
    userName: 'rajiv',
    password: 'password',
    deviceId: [
      { deviceId: 'M240103', name: "M240103" },
      { deviceId: 'M240099', name: 'M240099' },
      { deviceId: 'M240067', name: "M240067" },
      { deviceId: 'M240079', name: 'M240079' },
      { deviceId: 'M240108', name: 'M240108' }
    ]
  },
]

export default function Login() {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  let dispatch = useDispatch()
  const login = useSelector((state) => state.login);

  const [loading,setLoading] = useState(false)

  useEffect(() => {
    console.log(document.URL);
    const faviconUpdate = async () => {
      //grab favicon element by ID
      const favicon = document.getElementById("favicon");

      //check count value, if below 0 we change href property to our red circle image path
      if (document.URL.includes(octiotUrl)) {
        favicon.href = `/OCTIOT.ico`;
        document.title = "Energy Savings"
      }
      //if above 0, we set back to green
      else {
        favicon.href = `/favicon.ico`;
      }
    };
    //run our function here
    faviconUpdate();
  }, [])
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);

    let data = {
      userName: form.get('loginId'),
      password: form.get("password")
    }
    setLoading(true)
    // const userFound = user.filter((u)=>{if(u.userName===data.userName){
    //   return u
    // }})
    const userFound = await loginUserPhone(data.userName, data.password)
 
    if (!userFound?.success) {
      messages.error("Login Details Not Match")
      setLoading(false)
      return
    }
    setLoading(false)
    navigate(`/dashboard`)
  };

  useEffect(() => {
    if (Object.keys(login).length !== 0) {
      // If login object is not empty, navigate to dashboard
      navigate(`/dashboard`);
    }
  }, [login, navigate]);

  // useEffect(() => {
  // }, []);
  return (
    <div style={{ display: 'flex', height: "100vh", alignItems: "center" }}>
      <div style={{ backgroundColor: '#F6F8FD', width: '52vw', height: '100vh', padding: '1vw 3.5vh', alignItems: "center", display: "flex" }}>
        <div style={{ display: 'flex', position: 'fixed', top: '10px' }}>
          <img
            src={document.URL.includes(octiotUrl) ? images.octiot : images.loginLogo}
            style={{
              width: '10vw'
            }}
          />
        </div>
        <div>
          <img
            src={images.centerBanner}
            style={{
              width: '35vw',
              marginTop: '5vh'
            }}
          />
          <div
            style={{
              marginTop: '2vh',
              fontSize: '1.4rem',
              fontFamily: "manrope",
            }}>Saving <strong>energy</strong> and <strong>increasing life</strong> of your appliances and machines</div>
        </div>
        {!document.URL.includes(octiotUrl) && <div style={{
          position: 'absolute',
          bottom: '2.5vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '45vw'
        }}>
          <div
            style={{
              color: '#00000050',
              borderBottom: '1px solid #00000050',
              width: '100%',
              textAlign: 'left',
              paddingBottom: '1rem',
              fontFamily: "manrope",
            }}
          >Featured On</div>
          <div>
            <img
              src={images.bottomBanner}
              style={{
                width: '45vw',
                marginTop: '10px'
              }}
            />
          </div>
        </div>}
      </div>
      <div style={{ width: '48vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >

        <div style={{ width: '25vw', textAlign: 'left', borderBottom: '1px solid #CECECE', paddingBottom: '1rem' }}>
          <div
            style={{
              fontSize: '2rem',
              fontFamily: 'manrope',
              fontWeight: '800'
            }}
          >WELCOME BACK</div>
          <div
            style={{
              color: '#999999',
              fontFamily: "manrope",
              fontSize: '0.8rem'
            }}
          >Enter username and password to login!</div>
        </div>
        <form onSubmit={handleSubmit} style={{ width: '25vw' }}>

          <Form.Group as={Col} style={{ marginTop: '1.5rem' }}>

            <Form.Control
              type="text"
              placeholder="Username"
              required
              name="loginId"
              autoComplete='loginId'
              style={{
                height: '45px'
              }}
            />
          </Form.Group>
          <Form.Group as={Col} style={{ marginTop: '1.5rem' }}>

            <Form.Control
              type="password"
              placeholder='Password'
              required
              name="password"
              autoComplete='current-password'
              style={{
                height: '45px'
              }}
            />
          </Form.Group>
          <Button disabled={loading} type='submit' style={{ marginTop: '1.5rem', width: '100%', height: '45px' }} >Login</Button>
        </form>

      </div>
      <Toaster />
    </div>
  )
}
