import { loginDetails } from "../actionTypes"

export const loginDetailReducer = (state={},action)=>{
    const {type,payload} = action
    switch(type){
        case loginDetails.LOGINUSER:
            return payload
        default :
           return state
    }
}