import { reportTypes } from "../actionTypes";

export const addUnitReport = (reportId, data) => {
    return {
        type: reportTypes.ADD_UNIT,
        payload: {
            reportId, // Unique identifier for the report
            data
        }
    };
};
export const updateCallCount = (reportId)=>{
    return {
        type: reportTypes.UPDATE_CALL_COUNT,
        payload: {
            reportId, // Unique identifier for the report
        }
    };
}