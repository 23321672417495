const styles = {
    container: {
      background: "#F5F7FA",
      height: "92vh",
      width: "100%",
      // paddingTop: "2%",
      // paddingRight: "2%",
      // paddingLeft: "2%",
      padding:"0 2%",
      overflowY: "scroll",
    },
    smallBoxContainer: {
      marginTop: "20px",
    },
    chartContainer: {
      width: "100%",
      height: "317px",
      background: "#FFFFFF",
      borderRadius: "14px",
      marginTop: "2%",
      padding: "24px",
    },
    smallPieContainer: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
  
  export default styles;
  