import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function UnitSavingsDetailContainer({ time, unitsSaved, unitsConsumed }) {
    return (
        <DetailContainer
            name={"Unit Saving"}
            value={`${isNaN(unitsSaved / (unitsConsumed + unitsSaved)) ? "0" : ((unitsSaved / (unitsConsumed + unitsSaved)) * 100).toFixed(2)} %`}
            time={time}
            color={"rgba(50, 47, 200, 0.10)"}
            borderColor={"#322FC8"}
            image={images.unitConsumed !== undefined ? images.unitConsumed : 0}
        />
    )
}

export default UnitSavingsDetailContainer