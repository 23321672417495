export const moneySavedChart = (data) => {

    let val = 0

    for (let i of data) {
        if (Object.keys(i).length !== 0) {
            val += i.totalSaved
        }
    }
    return `${val.toFixed(2)} Rs`
}

export const totalCarbonConsume = (data) => {
    let val = 0

    for (let i of data) {

        console.log('hello')
        if (i !== undefined && Object.keys(i).length !== 0) {
            val += i.totalSaved
        }
    }
    return isNaN(val) ? 0 : val
}

export const onlineOffline = (data) => {
    let totaldevice = data.length
    let online = 0
    for (let i of data) {
        if (Object.keys(i).length === 0) continue
        if (i.connected) {
            online += 1
        }
    }


    return `${online}/${totaldevice}`
}