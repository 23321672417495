import { houseData } from "../actionTypes"

export const loadHouse = (id,data)=>{
    console.log('====================================');
    console.log(id,data,"load");
    console.log('====================================');
  return{
    type:houseData.ADD,
    payload:{id:id,house:data}
  }
}