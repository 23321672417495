import { houseData } from "../actionTypes"

export const houseDataReducer = (state={},action)=>{
    const {type,payload} = action
    switch(type){
        case houseData.LOADHOUSE:
            return payload
        default :
           return state
    }
}