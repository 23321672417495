import { snapshotDailyUnit } from "../actionTypes"

export const unitSnapshotData = (state = {}, action) => {
    const { type, payload } = action

    switch (type) {
        case snapshotDailyUnit.LOAD:
            let data = { ...state }
            if (data[payload.deviceId] === undefined) {
                data[payload.deviceId] = {}
            }
            if (data[payload.deviceId][payload.date] === undefined) {
                data[payload.deviceId][payload.date] = {}
            }
            data[payload.deviceId][payload.date] = payload.data
            return data
        default:
            return state
    }
}