import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function UnitConsumedDetailContainer({ phaseLoadLogs, phaseWiseData, phase, deviceId,unit }) {
  return (
    <DetailContainer
      name={`Units Consumed ${Object.keys(phaseLoadLogs[deviceId]).length > 1 ? Number(phase) + 1 : ''}`}
      value={`${unit !== undefined ? Number(unit).toFixed(2) : 0} kwh`}
      time={
        Object.keys(phaseLoadLogs)?.length !== 0 &&
          phaseWiseData.length !== 0
          ? phaseWiseData[phaseWiseData.length - 1].ts
          : 0
      }
      color={"#FDECEC"}
      borderColor={"#EF4444"}
      image={
        images.unitConsumed !== undefined ? images.unitConsumed : 0
      }
    />
  )
}

export default UnitConsumedDetailContainer