import axios from "axios"
import { serverUrl } from "../../../ApiRoute"
import { messages } from "../../common/notification";
import { type } from "@testing-library/user-event/dist/type";
import { scheduleType } from "../actionTypes";
import store from "..";
import { daysOfWeek } from "../../Settings/SettingsNavigation/ScheduleOperation/data";


export const loadSchedule=(data)=>{
    return{
        type:scheduleType.FETCH,
        payload:data
    }
}

export const removeSchedule=(data)=>{
    return{
        type:scheduleType.REMOVE,
        payload:data
    }
}
export const updateSchedule=(data)=>{
    return{
        type:scheduleType.UPDATE,
        payload:data
    }
}
export const actionInacctiveSchedule=(data)=>{
    return{
        type:scheduleType.ENABLE,
        payload:data
    }
}
export const createSchedule=(data)=>{
    return{
        type:scheduleType.CREATE,
        payload:data
    }
}



export const fetchScheduleApi=async(id)=>{
    await axios.post(`${serverUrl.sub}/v3/centralschedules/listByHouse`,{"houseId":id}).then((res)=>{
        const {success,data}=res.data
        if(success){
            store.dispatch(loadSchedule(data.schedules))
        }else{
            // store.dispatch(loadSchedule([]))
            let msg = res?.data?.message?(res?.data?.message):'Something went wrong'
            messages.error(msg)
        }
    }).catch((err)=>{
        let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
      
        messages.error(msg)
    })
}

export const createScheduleApi=async(data)=>{
   return await axios.post(`${serverUrl.web}/v3/centralschedules/create`,data).then((res)=>{
    
        const {success,data}=res.data
        if(success){
            store.dispatch(createSchedule(data.schedule))
            let msg = res?.data?.message?(res?.data?.message):'Schedule Created'
            messages.success(msg)
            return true
        }else{
            let msg = res?.data?.message?(res?.data?.message):'Something went wrong'
            messages.error(msg)
            return false
        }
    }).catch((err)=>{
        let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
        messages.error(msg)
        return false
    })
}

export const deleteScheduleApi=async(id)=>{
    await axios.post(`${serverUrl.web}/v3/centralschedules/remove`,{"scheduleId":id}).then((res)=>{
        const {success,data}=res.data
        if(success){
            store.dispatch(removeSchedule(id))
            let msg = res?.data?.message?(res?.data?.message):'Schedule Deleted'
            messages.success(msg)
        }else{
            let msg = res?.data?.message?(res?.data?.message):'Something went wrong'
            messages.error(msg)
        }
    }).catch((err)=>{
        let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
        messages.error(msg)
    })
}

export const activeDeactiveScheduleApi=async(datas)=>{
    await axios.post(`${serverUrl.web}/v3/centralschedules/update`,datas).then((res)=>{
        const {success,data}=res.data
        if(success){
            store.dispatch(actionInacctiveSchedule(datas))
            let msg = res?.data?.message?(res?.data?.message):'Schedule Updated'
            messages.success(msg)
        }else{
            let msg = res?.data?.message?(res?.data?.message):'Something went wrong'
            messages.error(msg)
        }
    }).catch((err)=>{
        let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
        messages.error(msg)
    })
}
export const uddateScheduleApi=async(datas)=>{
   return await axios.post(`${serverUrl.web}/v3/centralschedules/update`,datas).then((res)=>{
        const {success,data}=res.data
        if(success){
            store.dispatch(updateSchedule(data.schedule))
            let msg = res?.data?.message?(res?.data?.message):'Schedule Updated'
            messages.success(msg)
            return true
        }else{
            let msg = res?.data?.message?(res?.data?.message):'Something went wrong'
            messages.error(msg)
            return false
        }
    }).catch((err)=>{
        let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
        messages.error(msg)
        return false
    })
}


export const cronToTimeString=(cronExpression)=> {
    const fields = cronExpression.split(" ");
  
    if (fields.length < 5) {
      throw new Error("Invalid cron expression");
    }
  
    const minutes = fields[0];
    let hours = fields[1];
  
    let { hour, type } = convertTo12HourFormat(hours);
    return `${String(hour).padStart(2, "0")}:${minutes.padStart(2, "0")} ${type}`;
  }
  
  export function convertTo12HourFormat(hour) {
    hour = Number(hour);
    if (hour === 0) {
      return { hour: 12, type: "AM" };
    } else if (hour === 12) {
      return { hour: 12, type: "PM" };
    } else if (hour > 12) {
      return { hour: hour - 12, type: "PM" };
    } else {
      return { hour: hour, type: "AM" };
    }
  }
  export function convertTo12HourMinuteFormat(expression) {
    let [hour,minutes,seconds] = expression.split(':')
   let  type=""
    
    if (hour === 0) {
       hour= 12;
       type= "AM" ;
    } else if (hour === 12) {
      hour= 12;
      type= "PM";
    } else if (hour > 12) {
     hour= hour - 12;
     type= "PM";
    } else {
      hour= hour;
      type= "AM";
    }
     return `${String(hour).padStart(2, "0")}:${minutes.padStart(2, "0")} ${type}`;

  }

  export const cronExpressionToDays = (expression) => {
    let arrExpreression = expression.split(' ');
    let daysArray = arrExpreression[arrExpreression.length - 2].split(',');
    let day = [];
    for (let d of daysArray) {
        day.push(daysOfWeek[d - 1]);
    }
    let letterArray = "";
    for (let letter of day) {
        if(letter===undefined) continue
        letterArray = letterArray.concat(' ', letter[0]);
    }
    return letterArray.trim(); // trim to remove the leading space
}
export const cronExpressionToDaysFormate = (expression) => {

    const arrExpression = expression.split(' ');
    const daysArray = arrExpression[arrExpression.length - 2].split(',');
    const activeDays = new Set(daysArray.map(d => parseInt(d) - 1)); // Convert to 0-based index
  
    return (
      <div>
        {daysOfWeek.map((day, index) => (
          <span key={index} style={{ fontWeight: activeDays.has(index) ? 'bold' : 'normal',color:activeDays.has(index)?"black":'#dddddd' }}>
            {day[0]}{" "}
          </span>
        ))}
      </div>
    );
  };
  
  // Usage in a React component
  export default function App() {
    const expression = "0 9 ? * 1,2,3,5,6,7 *";
    return (
      <div>
        {cronExpressionToDays(expression)}
      </div>
    );
  }
  