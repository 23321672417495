import Lottie from 'lottie-react'
import React from 'react'
import loading from '../../assets/lottie/Loading.json'

function Loading() {
  return (
    <div style={{width:'100vw',height:'100vh',position:'fixed',top:0,left:0,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div >
        <Lottie animationData={loading} loop={true} style={{height:500}} />
        </div>
    </div>
  )
}

export default Loading