import { environSyncsAction, thermoSmartsAction, weatherSensorsAction } from "../actionTypes"

export const loadWeatherReducer=(state=[],action)=>{
    const { type, payload } = action
    switch (type) {
        case weatherSensorsAction.LOAD:
            return payload
        default:
            return state
    }
}



export const loadThermoSmartReducer=(state=[],action)=>{
    const { type, payload } = action
    switch (type) {
        case thermoSmartsAction.LOAD:
            return payload
        default:
            return state
    }
   
}


export const loadEnviroSynncReducer=(state=[],action)=>{
    const { type, payload } = action
    switch (type) {
        case environSyncsAction.LOAD:
            return payload
        default:
            return state
    }
  
}