import { Box } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { scheduleType } from "./data";
import { uddateScheduleApi } from "../../../redux/action/schedule";

function UpdateScheduleTime({ data, handleClose }) {
  const [time, setTime] = useState(`2022-04-17T15:30`);
  const [date, setDate] = useState(null);
  useEffect(() => {
    setTime(
      `2022-04-17T${
        data.dataa.type === scheduleType.cron
          ? `${data.dataa.expression.split(" ")[1]}:${
              data.dataa.expression.split(" ")[0]
            }`
          : data.dataa.expression.split("T")[1]
      }`
    );
    console.log(data.dataa.type===scheduleType.at ? moment(new Date( data.dataa.expression)).format('YYYY-MM-DD'):moment(new Date()).format('YYYY-MM-DD'));
    setDate(data.dataa.type===scheduleType.at ? moment(new Date( data.dataa.expression)).format('YYYY-MM-DD'):moment(new Date()).format('YYYY-MM-DD'))

  }, [data]);
  const handleSubmit = () => {
    let expression = "";
    if (data.dataa.type === scheduleType.cron) {
      expression = createCronExpression(
        moment(time).format("HH:mm"),
        data.dataa.expression.split("*")[1]
      );
    } else {
      expression = `${date}T${moment(time).format("HH:mm:ss")}`;
    }
    uddateScheduleApi({
      scheduleId: data.dataa._id,
      ...data.dataa,
      expression,
    }).then((res) => {
      if (res) {
        handleClose();
      }
    });
  };
  function createCronExpression(timeStr, weekdays) {
    const [hour, minute] = timeStr.split(":").map(Number);
    if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
      throw new Error("Invalid time format");
    }
    const cronWeekdays = weekdays;
    return `${minute} ${hour} ? *${cronWeekdays}*`;
  }
 
  return (
    <div>
      <Box className="mt-3" sx={{ width: "400px", padding: "20px" }}>
        
        {data.dataa.type === scheduleType.at && (
          <Box className="mt-3 mb-3" sx={{ width: "100%" }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{ width: "100%" }}
            >
              <DatePicker
                label="Date Picker"
                format="YYYY-MM-DD"
                value={dayjs(date)}
                onChange={(e) => setDate(moment(e.$d).format("YYYY-MM-DD"))}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </Box>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: "100%" }}>
          <MobileTimePicker
            value={dayjs(time)}
            onChange={(e) => setTime(e.$d)}
            sx={{ width: "100%" }}
            onAccept={() => handleSubmit()}
            onClose={() => handleClose()}
            label={"Time Picker"}
          />
        </LocalizationProvider>
      </Box>
    </div>
  );
}

export default UpdateScheduleTime;
