import React from "react";
import { useDispatch } from "react-redux";
import { loginDetail } from "../redux/action/login";
import { clearDeviceDropdownData } from "../redux/action/deviceDropdownSelection";
import { resetState } from "../redux/actionTypes";

function LogOut() {
  const dispatch = useDispatch();

  return (
    <div
      onClick={() => {
        dispatch(loginDetail({}));
        dispatch(clearDeviceDropdownData());
        dispatch({type:resetState.RESET})
      }}
      style={{cursor:'pointer'}}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
      >
        <path
          d="M2.84588 1.88889H9.52865V0H2.84588C1.79574 0 0.936523 0.85 0.936523 1.88889V15.1111C0.936523 16.15 1.79574 17 2.84588 17H9.52865V15.1111H2.84588V1.88889ZM18.1208 8.5L14.302 4.72222V7.55556H6.66461V9.44444H14.302V12.2778L18.1208 8.5Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default LogOut;
