import store from "..";
import { selectedHouseData,selectedRoomData,selectedRoomAllDevices,selectedDeviceData,clearDropdownData } from "../actionTypes"

export const selectedRoom = (data) => {
    return {
        type: selectedRoomData.LOAD_SELECTED_ROOM_DATA,
        payload: data
    }
}

export const selectedHouse = (data) => {
    return {
        type: selectedHouseData.LOAD_SELECTED_HOUSE_DATA,
        payload: data
    }
}

export const selectedRoomDevices = (data) => {
    return {
        type: selectedRoomAllDevices.LOAD_SELECTED_ROOM_DEVICES,
        payload: data
    }
}

export const selectedDeviceFromDropdown = (data) => {
    return {
        type: selectedDeviceData.LOAD_SELECTED_DEVICE_DATA,
        payload: data
    }
}

export const clearDeviceDropdownData = () => {
    return {
        type: clearDropdownData.CLEAR_DATA,
    }
}