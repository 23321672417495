import React from 'react'
import './HeaderOperation.css'
function HeaderOperation({headerName}) {
  return (
    <div className='d-flex flex-column gap-2'>
      <div className='headerOperationMainHeader'>{headerName}</div>
      <div>
        <span className='subHeaderOperation'>Home {`>`}   </span>
        <span className='subHeaderOperation'>Operations {`>`}   </span>
        <span className='subHeaderOperation2'>{headerName}</span>
      </div>
    </div>
  )
}

export default HeaderOperation