import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';
import { onlineOffline } from "../common/util";

function OnlineStatusDetailContainer({ time, selectedDeviceSelectedDayData }) {

  console.log(selectedDeviceSelectedDayData,'3ered')

  return (
    <DetailContainer
      name={"Device Status"}
      // value={onlineOffline(selectedDeviceSelectedDayData)}
      value={selectedDeviceSelectedDayData[0].connected?'Online':'Offline'}
      time={time}
      color={"rgba(118, 190, 194, 0.10)"}
      borderColor={"#76BEC2"}
      image={images.deviceStatus}
    />
  )
}

export default OnlineStatusDetailContainer