import { selectedDate } from "../actionTypes"

export const selectedDayReducer = (state = '', action) => {

   let { type, payload } = action

   switch (type) {
      case selectedDate.LOAD_SELECTED_DATE:
         return payload
      default:
         return state
   }
}