import { toadyData } from "../actionTypes"

export const todayDataReducer = (state={},action)=>{
   let {type,payload} = action
   
   switch(type){
      case toadyData.LOAD:
         return payload
      case toadyData.REMOVE_LOAD:
         return payload
      default :
         return state
   }
}

export const todayDataOfDeviceReducer = (state={},action)=>{
   let {type,payload} = action

   switch(type){
      case toadyData.LOADTWO:
         return payload
         case toadyData.REMOVE_LOADTWO:
            return payload
      default :
         return state
   }
}