
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function MoneySavedDetailContainer({ time, moneySaved }) {


  return (
    <DetailContainer
      name={"Total Money Saved"}
      value={`${moneySaved.toFixed(2)}`}
      time={time}
      color={"rgba(50, 47, 200, 0.10)"}
      borderColor={"#322FC8"}
      image={images.moneySaved !== undefined ? images.moneySaved : 0}
    />
  )
}

export default MoneySavedDetailContainer