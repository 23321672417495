import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Checkbox, Switch } from '@mui/material';
import { messages } from '../../../common/notification';

const SelectDevice = forwardRef(({ data,editData }, ref) => {
    
    const [checkedData, setChecked] = useState({});
    useEffect(()=>{
        if(editData!==undefined && Object.keys(editData).length!==0){
            setChecked(editData)
        }
    },[editData])
    useImperativeHandle(ref, () => ({
        getData() {
            return checkedData;
        },
    }));

    const handleCheck = (event) => {
        if (event.target.checked) {
            let obj = {
                action: 'EnergISync/Control',
                payload: {
                    deviceId: data.deviceId,
                    command: 0,
                    controllerType: 'centralSchedule',
                    controllerId: 'centralSchedule',
                    control: true
                },
                houseId:data.houseId
            };
            setChecked(obj);
        } else {
            setChecked({});
        }
    };

    const handleOnOOff = (event) => {
        if (Object.keys(checkedData).length !== 0) {
            setChecked({
                ...checkedData,
                payload: {
                    ...checkedData.payload,
                    command: event.target.checked ? 100 : 0
                }
            });
        } else {
            messages.error("First Select that Device");
        }
    };

    return (
        <Paper elevation={2} className='p-2 mb-3 d-flex justify-content-between align-items-center' key={data._id}>
             <Box className="row w-75">
             <Box className="col-6">{data?.houseName} </Box>
            <Box className="col-6">{data.roomName} {data.name ? data.name : data.deviceId}</Box>
            </Box>
            <Box className="d-flex gap-2 w-25">
                <Switch onChange={handleOnOOff} checked={checkedData.payload?.command === 100} />
                <Checkbox checked={Object.keys(checkedData).length>0?true:false} onChange={handleCheck} />
            </Box>
        </Paper>
    );
});

export default SelectDevice;
