import React from 'react';
import { Container, Grid, Paper, Checkbox, IconButton, Breadcrumbs, Link, Typography, Button, Box } from '@mui/material';
import { Add, MoreVert, AcUnit, WbIncandescent, Toys } from '@mui/icons-material';

const devices = [
  { id: 1, serial: '01', property: 'Aliste HQ', type: 'AC 1', icon: <AcUnit />, room: 'Sales', name: 'Vikas Side', status: 'Online' },
  { id: 2, serial: '02', property: 'Aliste HQ', type: 'Fan 1', icon: <Toys />, room: 'Sales', name: 'Vikas Side', status: 'Online' },
  { id: 3, serial: '03', property: 'Aliste HQ', type: 'Light 1', icon: <WbIncandescent />, room: 'Sales', name: 'Vikas Side', status: 'Online' },
  { id: 4, serial: '04', property: 'AC 2', type: 'AC 2', icon: <AcUnit />, room: 'Sales', name: 'Vikas Side', status: 'Online' },
  { id: 5, serial: '05', property: 'Fan 2', type: 'Fan 2', icon: <Toys />, room: 'Sales', name: 'Vikas Side', status: 'Online' },
];

const EditName = () => {
  const headerStyle = { textAlign: 'center', fontWeight: 'bold',  };
  const cellStyle = { textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' };

  return (
    <Container maxWidth="xl" style={{padding:'0px'}}>

      {/* <Button variant="contained" color="primary" startIcon={<Add />} style={{ float: 'right', marginBottom: '10px' }}>
        Add
      </Button> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: '16px', display: 'flex', alignItems: 'center', backgroundColor: '#f8f9fa' }}>
            <Box style={{ flex: 1 }}></Box>
            <Typography variant="body1" style={{ ...headerStyle, flex: 1 }}>S. no.</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Property</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Type</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 1 }}>Icon</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Room</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Name</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Device Status</Typography>
            <Box style={{ flex: 1 }}></Box>
          </Paper>
        </Grid>
        {devices.map((device) => (
          <Grid item xs={12} key={device.id}>
            <Paper style={{ padding: '16px', display: 'flex', alignItems: 'center' }}>
              <Box style={{ flex: 1 }}>
                <Checkbox />
              </Box>
              <Typography variant="body1" style={{ ...cellStyle, flex: 1 }}>{device.serial}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.property}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.type}</Typography>
              <Box style={{ ...cellStyle, flex: 1, justifyContent: 'center' }}>{device.icon}</Box>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.room}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.name}</Typography>
              <Box style={{ ...cellStyle, flex: 2, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <span style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: device.status === 'Online' ? '#2ecc71' : '#e74c3c', marginRight: 8 }}></span>
                {device.status}
              </Box>
              <Box style={{ flex: 1 }}>
                <IconButton><MoreVert /></IconButton>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EditName;
