import React, { useEffect, useRef, useState } from 'react';
import { Drawer, Box, IconButton, Typography, TextField, Button, InputLabel, RadioGroup, FormControlLabel, Radio, Paper } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { MobileTimePicker, LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SelectDevice from './SelectDevice';
import moment from 'moment';
import { messages } from '../../../common/notification';
import './Schedule.css'
import { createScheduleApi, uddateScheduleApi } from '../../../redux/action/schedule';
import { daysOfWeek, scheduleType } from './data';

function ScheduleDrawer({ handleCloseDrawer, drawerOpen, energiSync, id,editSchedule }) {
   
    const [name, setName] = useState("");
    const [time, setTime] = useState(`2022-04-17T15:30`);
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [frequency, setFrequency] = useState('cron');
    const [loading, setLoadig] = useState(false)
    const [dataFromChildren, setDataFromChildren] = useState([]);
    const [days, setDays] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
    });
   
    useEffect(()=>{
       setFrequency(Object.keys(editSchedule).length>0?editSchedule.type:'cron')
       setTime(`2022-04-17T${Object.keys(editSchedule).length>0?(editSchedule.type===scheduleType.cron?(`${editSchedule.expression.split(' ')[1]}:${editSchedule.expression.split(' ')[0]}`):editSchedule.expression.split('T')[1]):"15:30"}`)
       setDate(Object.keys(editSchedule).length>0 && editSchedule.type===scheduleType.at ? moment(new Date(editSchedule.expression)).format('YYYY-MM-DD'):moment(new Date()).format('YYYY-MM-DD'))
       setName(Object.keys(editSchedule).length>0?editSchedule.name:"")
       if(Object.keys(editSchedule).length>0 && editSchedule.type===scheduleType.cron){
        let expression = editSchedule.expression.split(" ")
        let dayArray = expression[expression.length-2].split(",")
        let newObj={}
        for(let id of dayArray){
            let num = Number(id)
            newObj={...newObj,[id]:true}
        }
        setDays({...days,...newObj})
       }else{
        setDays({
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
        })
       }
    },[editSchedule])
    const childRefs = useRef([]);

    useEffect(() => {
        // Ensure childRefs.current is the correct length
        if (childRefs.current.length !== energiSync.length) {
            childRefs.current = Array(energiSync.length)
                .fill()
                .map((_, i) => childRefs.current[i] || React.createRef());
        }
    }, [energiSync.length]);



    function createCronExpression(timeStr, weekdays) {
        const [hour, minute] = timeStr.split(':').map(Number);
        if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
            throw new Error("Invalid time format");
        }
        const cronWeekdays = weekdays.join(',');
        return `${minute} ${hour} ? * ${cronWeekdays} *`;
    }

    const handleSubmit = async() => {
        setLoadig(true)
        if (name === "") {
            messages.error('Enter Schedule Name');
            setLoadig(false)
            return;
        }
        if (time === undefined || time === "") {
            messages.error('Select Valid Time');
            setLoadig(false)
            return;
        }
        let selectedDays = [];

        let expression = ""
        if (frequency === scheduleType.cron) {
            Object.keys(days).forEach((day) => {
                if (days[day]) {
                    selectedDays.push(parseInt(day));
                }
            });
            if (selectedDays.length === 0) {
                messages.error("Select Days");
                setLoadig(false)
                return;
            }
            expression = createCronExpression(moment(time).format('HH:mm'), selectedDays);
        } else {
            expression = `${date}T${moment(time).format('HH:mm:ss')}`
        }
        // Collect data from all children
        const collectedData = childRefs.current.map(ref => ref.current?.getData());
        setDataFromChildren(collectedData);
       
        let finalActions =  collectedData.filter(item => item && Object.keys(item).length > 0)
        if(finalActions===undefined || finalActions.length===0){
            messages.error("Select Device");
            setLoadig(false)
            return; 
        }
        let allHouseActionData = collectedData.filter(item => item && Object.keys(item).length > 0)
        let dataHouse = {}
        for(let c of allHouseActionData){
            if(dataHouse[c.houseId]===undefined){
                dataHouse[c.houseId]=[]
            }
        
             dataHouse[c.houseId].push(c)
        }
        const apiData = []
        for(let idHouse of Object.keys(dataHouse)){
            let data = {
                "name": name,
                "house": idHouse,
                "type": frequency,
                "expression": expression,
                "enabled": true,
                "actions": dataHouse[idHouse],
                "tags": []
            };
            apiData.push(data)
        }
        // let data = {
        //     "name": name,
        //     "house": id,
        //     "type": frequency,
        //     "expression": expression,
        //     "enabled": true,
        //     "actions": collectedData.filter(item => item && Object.keys(item).length > 0),
        //     "tags": []
        // };
        console.log('====================================');
        console.log(apiData);
        console.log('====================================');
        
        if(Object.keys(editSchedule).length===0){
        for(let scheduleApiData of apiData){
           await createScheduleApi(scheduleApiData, handleCloseDrawer).then(res => {
                if (res) {
                  
                }
            })
        }
        handleCloseDrawer()
        setLoadig(false)
        }else if(Object.keys(editSchedule).length>0){
            for(let scheduleApiData of apiData){
            uddateScheduleApi({scheduleId:editSchedule._id,...scheduleApiData}).then(res => {
                if (res) {
                    handleCloseDrawer()
                    setLoadig(false)
                }
            })
        }
        }
        // Proceed with the API call or further processing
    };

    return (
        <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer} PaperProps={{ style: { width: '40vw', backgroundColor: 'rgb(242 242 242)' } }}>
            <Box sx={{padding:"16px"}}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={handleCloseDrawer}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5" style={{ marginLeft: '7%', fontWeight: 'bold' }}>Schedules</Typography>
            </Box>

            <Box className="mt-3">
                <TextField label="Name" fullWidth size='small' onChange={(e) => setName(e.target.value)} value={name} />
            </Box>
            <RadioGroup
                row
                value={frequency}
                onChange={(e) => { setFrequency(e.target.value) }}
                className='mt-3'
            >
                <FormControlLabel value="cron" control={<Radio />} label="Repeat Every" />
                <FormControlLabel value="at" control={<Radio />} label="Repeat Once" />
            </RadioGroup>

            <Box className="mt-3" sx={{ width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }} >
                    <MobileTimePicker value={dayjs(time)} onChange={(e) => { setTime(e.$d) }} sx={{ width: '100%' }} />
                </LocalizationProvider>
            </Box>

            {
                frequency === "cron" ? (
                    <Box className="mt-3">
                        <InputLabel>Select Days</InputLabel>
                        <Box className="d-flex justify-content-between mt-2 gap-2 hideScrollbar overflow-x-scroll">
                            {daysOfWeek.map((day, index) => (
                                <Box
                                    key={index}
                                    className='dayContainer'
                                    sx={{
                                        backgroundColor: days[index + 1] ? 'rgba(32, 80, 255, 0.80)' : 'aliceblue',
                                        color: days[index + 1] ? '#FFF' : '#000'
                                    }}
                                    onClick={() => setDays({ ...days, [index + 1]: !days[index + 1] })}
                                >
                                    {day}
                                </Box>
                            ))}
                        </Box>

                    </Box>
                ) : <Box className="mt-3" sx={{ width: '100%' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }} >
                        <DatePicker
                            label="Date Picker"
                            format="YYYY-MM-DD"
                            defaultValue={dayjs(date)}
                            onChange={(e) => setDate(moment(e.$d).format('YYYY-MM-DD'))}
                            sx={{ width: '100%' }}
                        />
                    </LocalizationProvider>
                </Box>
            }
            <InputLabel className='mt-3'>Select Device</InputLabel>
            <Paper elevation={2} className='p-2 mb-3 d-flex justify-content-between align-items-center'>
            <Box className="row w-75">
            <Box className='selectPaperHeader col-6'>Property Name</Box>
            <Box className='selectPaperHeader col-6'>Room/Appliance Name</Box>
            </Box>
            <Box className="d-flex gap-2 w-25">
                <span className='selectPaperHeader'>On/Off</span>
                <span className='selectPaperHeader'>Select</span>
            </Box>
        </Paper>
            <Box className='mt-2 selectDeviceContainer'>
                {energiSync.map((data, i) => (
                    <SelectDevice key={data._id} data={data} editData={Object.keys(editSchedule).length>0?editSchedule.actions.find(e=>e.payload.deviceId===data.deviceId):{}} ref={childRefs.current[i]} />
                ))}
            </Box>
            </Box>
            <Box className="bottomSubmitSchedule">
            <Button disabled={loading} onClick={handleSubmit} variant='contained' fullWidth>Submit</Button>
            </Box>
        </Drawer>
    );
}

export default ScheduleDrawer;
