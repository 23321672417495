import axios from "axios"
import { serverUrl } from "../../ApiRoute"
import store from "../redux"
import { messages } from "./notification"
import { fetchAndStoreMultipleDevicesTodayData } from "../redux/action/totalData"
import { loadUnitSnapshot } from "../redux/action/dailyUnit"
import { config } from "../../config"

export const deviceControl = async (deviceId, command) => {
    try {
      await axios.post(`${serverUrl.aws}/control`, {
        "deviceId": deviceId,
        "switchId": 0,
        "command": command,
        "id": new Date().getTime().toString().slice(5, 13),
        "controllerId": store.getState().login.userName,
        "controllerType": "autoOn",
        "control": true
      });
      
      console.log(deviceId,'haaaalalalalaaa')

      await fetchAndStoreMultipleDevicesTodayData([{ deviceId: deviceId }]);
    } catch (error) {
      messages.error("Something went wrong");
    }
  };


export const deviceDetails = async (id) => {

    return await axios.post(`${serverUrl.aws}/details`, {
        "deviceId": id
    }).then(res => {
        if (res.data.success) {
            return res.data.data.device
        } else {
            return {}
        }
    }).catch(err => {
        return {}
    })
}

export const autoTimersStart = async (id) => {

    return await axios.post(`${serverUrl.aws}/autoTimers/start`, {
        "deviceId": id,
        "control": true,
        "command": 100
    }).then(res => {
        fetchAndStoreMultipleDevicesTodayData([{ deviceId: id }])
        if (res.data.success) {
            return res.data.data.device
        } else {
            return {}
        }
    }).catch(err => {
        return {}
    })
}

export const autoTimerStop = async (id) => {
    return await axios.post(`${serverUrl.aws}/autoTimers/stop`, {
        "deviceId": id,
        "control": true,
        "command": 0
    }).then(res => {
        fetchAndStoreMultipleDevicesTodayData([{ deviceId: id }])
        if (res.data.success) {
            return {}
        } else {
            return {}
        }
    }).catch(err => {
        return {}
    })
}

export const thermoAwsApi = async (id,date) => {
    let deviceId=id
    let dateWithoutHours = new Date(new Date(date).setHours(0, 0, 0, 0)).toDateString()
    return await axios.post(`${serverUrl.thermoAws}/analysis/day`, {
        "deviceId": id,
        "day": date,
    }).then(res => {
        if (res.data.success) {
                store.dispatch(loadUnitSnapshot({ data: {...res.data.data.snapshot,type:config.deviceType.thermoSmarts}, deviceId: deviceId, date: dateWithoutHours }))
            // store.dispatch(loadUnitSnapshot({ data: res.data.data.snapshot, deviceId: deviceId, date: dateWithoutHours }))
            return { ...res.data.data.snapshot, deviceId: deviceId,type:config.deviceType.thermoSmarts };
        } else {
            return {};
        }
    }).catch(err => {
        return {}
    })
}
export const weatherAwsApi = async (id,date) => {
    let deviceId=id
    let dateWithoutHours = new Date(new Date(date).setHours(0, 0, 0, 0)).toDateString()
    return await axios.post(`${serverUrl.weatherAws}/analysis/day`, {
        "deviceId": id,
        "day": date,
    }).then(res => {
        if (res.data.success) {
            store.dispatch(loadUnitSnapshot({ data: {...res.data.data.snapshot,type:config.deviceType.weatherSensors}, deviceId: deviceId, date: dateWithoutHours })) 
            // store.dispatch(loadUnitSnapshot({ data: res.data.data.snapshot, deviceId: deviceId, date: dateWithoutHours }))
            return { ...res.data.data.snapshot, deviceId: deviceId,type:config.deviceType.weatherSensors };
        } else {
            return {};
        }
    }).catch(err => {
        return {}
    })
}

export const environAwsApi = async (id,date) => {
    let deviceId=id
    let dateWithoutHours = new Date(new Date(date).setHours(0, 0, 0, 0)).toDateString()
    return await axios.post(`${serverUrl.environAws}/analysis/day`, {
        "deviceId": id,
        "day": date,
    }).then(res => {
        if (res.data.success) {
            store.dispatch(loadUnitSnapshot({ data: {...res.data.data.snapshot,type:config.deviceType.environSyncs}, deviceId: deviceId, date: dateWithoutHours }))
            // store.dispatch(loadUnitSnapshot({ data: res.data.data.snapshot, deviceId: deviceId, date: dateWithoutHours }))
            return { ...res.data.data.snapshot, deviceId: deviceId,type:config.deviceType.environSyncs };
        } else {
            return {};
        }
    }).catch(err => {
        return {}
    })
}

