import {
  Box,
  Container,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { scheduleType } from "./data";
import {
  convertTo12HourMinuteFormat,
  cronExpressionToDays,
  cronExpressionToDaysFormate,
  cronToTimeString,
} from "../../../redux/action/schedule";
import UpdateScheduleTime from "./UpdateScheduleTime";
import { useSelector } from "react-redux";

function ApplianceOverViewTable({ schedules, houseName, findName }) {
  const mutipleHouse = useSelector((state) => state.mutipleHouse);
  const [deviceWiseSchedule, setDeviceWiseSchedule] = useState({});
  const [schChange, setSchChange] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    let data = {};
    if (schedules.length !== 0) {
      for (let sche of schedules) {
        if (data[sche.house] === undefined) {
          data[sche.house] = {};
        }
        for (let action of sche.actions) {
          if (data[sche.house][action.payload.deviceId] === undefined) {
            data[sche.house][action.payload.deviceId] = [];
          }

          let obj = {
            ...action.payload,
            ...sche,
          };
          data[sche.house][action.payload.deviceId].push(obj);
        }
      }
    }
    setDeviceWiseSchedule(data);
  }, [schedules]);
  const findOnOffTime = (data, type, decision=true) => {
    if (!decision) return [];
    let filterData = data.filter((id) => id.command === type);
    let time = [];
    if (filterData !== undefined) {
      for (let d of filterData) {
        let val =
          d.type === scheduleType.cron
            ? cronToTimeString(d.expression)
            : convertTo12HourMinuteFormat(d.expression.split("T")[1]);
        time.push({ timee: val, dataa: d, type });
      }
      return time;
    } else {
      return [{ timee: "", dataa: {}, type: "notSet" }];
    }
  };
  const handleclick = (data, event) => {
    setSchChange(data);
    handleClick(event);
  };
  const dataMaking  = (data)=>{
    const groupedData = {};
    Object.values(data).forEach(houseData => {
        Object.entries(houseData).forEach(([deviceId, schedules]) => {
            if (!groupedData[findName(deviceId)]) {
                groupedData[findName(deviceId)] = [];
            }
            groupedData[findName(deviceId)].push(...schedules);
        });
    });
   
    return groupedData
  }

  return (
    <Container
      maxWidth="xl"
      style={{
        padding: "0px",
        marginTop: "50px",
        paddingBottom: "500px !important",
        backgroundColor: "#F7F9FF",
      }}
    >
      <TableContainer
        style={{
          backgroundColor: "#FFF",
          borderRadius: "11.057px",
          border: "0.691px solid #CED9FF",
        }}
      >

        <Table>
          <TableHead>
            <TableRow className=" position-relative">
              <TableCell
                style={{ textAlign: "center", fontWeight: "bold" }}
                className="sticky"
              ></TableCell>
              {Object.keys(deviceWiseSchedule).length > 0 &&
                Object.keys(dataMaking(deviceWiseSchedule)).map((idD, index) => {
                      return (
                        <TableCell
                          key={idD}
                          colSpan={2}
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {idD}
                        </TableCell>
                      );
                    })
              }
            </TableRow>
            <TableRow className=" position-relative">
              <TableCell
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  minWidth: "250px",
                }}
                className="sticky"
              >
                Property
              </TableCell>
              {
                Object.keys(deviceWiseSchedule).length > 0 &&   Object.keys(dataMaking(deviceWiseSchedule)).map((id, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableCell
                        key={`${id}-${index}0`}
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          minWidth: "250px",
                          borderLeft: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        On Time
                      </TableCell>
                      <TableCell
                        key={`${id}-${index}1`}
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          minWidth: "250px",
                          borderLeft: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        Off Time
                      </TableCell>
                    </React.Fragment>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(deviceWiseSchedule).length > 0 &&
              Object.keys(deviceWiseSchedule)?.map((houseIdM, i) => {
                return (
                  <TableRow className=" position-relative">
                    <TableCell
                      key={houseIdM}
                      style={{
                        textAlign: "center",
                        borderLeft: "1px solid rgba(224, 224, 224, 1)",
                      }}
                      className="sticky"
                    >
                      {mutipleHouse[houseIdM]?.houseName}
                    </TableCell>
                    {Object.keys(deviceWiseSchedule).length > 0 && Object.keys(dataMaking(deviceWiseSchedule))?.map(
                            (deviceId, index) => {
                              let showData=dataMaking(deviceWiseSchedule)[deviceId]?.filter((e)=>e.house===houseIdM) || {}
                          
                              return (
                              <React.Fragment key={index}>
                                <TableCell
                                  key={`${deviceId}-${index}0`}
                                  style={{
                                    textAlign: "center",
                                    borderLeft:
                                      "1px solid rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  {findOnOffTime(
                                    showData,
                                    100, 
                                  ).map((sch) => {
                                    console.log(sch,"sch");
                                    return (
                                      <Box
                                        sx={{ cursor: "pointer",display:'flex',justifyContent:'center' }}
                                        key={sch.dataa._id}
                                        aria-describedby={id}
                                        onClick={(event) =>
                                          handleclick(sch, event)
                                        }
                                      >
                                        {sch.timee}
                                        {"  "}
                                        ({sch.dataa.type === scheduleType.cron && cronExpressionToDaysFormate(sch.dataa.expression)})
                                      </Box>
                                    );
                                  })}
                                </TableCell>
                                <TableCell
                                  key={`${deviceId}-${index}1`}
                                  style={{
                                    textAlign: "center",
                                    borderLeft:
                                      "1px solid rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  {findOnOffTime(
                                    showData,
                                    0
                                  ).map((sch) => {
                                    return (
                                      <Box
                                        sx={{ cursor: "pointer",display:'flex',justifyContent:'center'  }}
                                        key={sch.dataa._id}
                                        aria-describedby={id}
                                        onClick={(event) =>
                                          handleclick(sch, event)
                                        }
                                      >
                                        {sch.timee}
                                        {"  "}
                                        ({sch.dataa.type === scheduleType.cron && cronExpressionToDaysFormate(sch.dataa.expression)})
                                      </Box>
                                    );
                                  })}
                                </TableCell>
                              </React.Fragment>
                            )
                          }
                          )
                      }
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ height: "50px", backgroundColor: "rgb(245, 247, 250)" }}></Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <UpdateScheduleTime data={schChange} handleClose={handleClose} />
      </Popover>
    </Container>
  );
}

export default ApplianceOverViewTable;
