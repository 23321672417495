import axios from "axios";
import { collectiveData } from "../actionTypes";
import { serverUrl } from "../../../ApiRoute";
import store from "..";
import { loadUnitSnapshot } from "./dailyUnit";

export const loadMultipleData = (data) => {
    return {
        type: collectiveData.LOAD,
        payload: data
    };
};

export const fetchSingleDeviceTodayData = async (deviceId) => {

    // const snapshotUnit = store.getState().snapshotUnit

    let todayDateInStringWithoutHours = new Date(new Date().setHours(0, 0, 0, 0)).toDateString();

    try {
        const res = await axios.post(`${serverUrl.aws}/analysis/today`, {
            "deviceId": deviceId,
        });

        if (res.data.success) {
            console.log('haaaalalalalaaa')
            store.dispatch(loadUnitSnapshot({ data: res.data.data.snapshot, deviceId: deviceId, date: todayDateInStringWithoutHours }))

            return { ...res.data.data.snapshot, deviceId: deviceId };
        } else {
            return {};
        }
    } catch (err) {
        console.error(err);
        return {};
    }
};

export const fetchAndStoreMultipleDevicesTodayData = async (deviceIds) => {
    let combineData = [];
    console.log('====================================');
    console.log(deviceIds,"fun1234");
    console.log('====================================');
    for (let d of deviceIds) {
        try {
            const res = await fetchSingleDeviceTodayData(d.deviceId);
            combineData.push(res);
        } catch (err) {
            console.error(err);
        }
    }
    return combineData
};

export const fetchAndStoreSingleDeviceSelectedDayData = async (deviceId, date) => {
    const snapshotUnit = store.getState().snapshotUnit

    let dateWithoutHours = new Date(new Date(date).setHours(0, 0, 0, 0)).toDateString()

    try {
        const res = await axios.post(`${serverUrl.aws}/analysis/day`, {
            "deviceId": deviceId,
            "day": date
        });
        if (res.data.success) {

            if (snapshotUnit && snapshotUnit[deviceId] && snapshotUnit[deviceId][dateWithoutHours]) {

                if (res.data.data.snapshot.version !== snapshotUnit[deviceId][dateWithoutHours].version) {
                    store.dispatch(loadUnitSnapshot({ data: res.data.data.snapshot, deviceId: deviceId, date: dateWithoutHours }))
                }
            }
            else {
                store.dispatch(loadUnitSnapshot({ data: res.data.data.snapshot, deviceId: deviceId, date: dateWithoutHours }))
            }

            // 
            // store.dispatch(loadUnitSnapshot({ data: res.data.data.snapshot, deviceId: deviceId, date: dateWithoutHours }))
            return { ...res.data.data.snapshot, deviceId: deviceId };
        } else {
            return {};
        }
    } catch (err) {
        console.error(err);
        return {};
    }
};

export const fetchAndStoreMulipleDevicesSelectedDayData = async (deviceIds, date) => {

    let combineData = [];
    for (let d of deviceIds) {
        try {
            const res = await fetchAndStoreSingleDeviceSelectedDayData(d.deviceId, date);
            combineData.push(res);
        } catch (err) {
            console.error(err);
        }
    }

    return combineData

    // store.dispatch(loadMultipleData(combineData));
};

export const fetchDeviceDataWithStartEndTime = async (deviceId, startTime, endTime) => {
    try {
        const res = await axios.post(`${serverUrl.aws}/analysis/device`, {
            "deviceId": deviceId,
            "startTime": startTime,
            "endTime": endTime
        });
        if (res.data.success) {
            return { ...res.data.data.snapshot, deviceId: deviceId };
        } else {
            return {};
        }
    } catch (err) {
        console.error(err);
        return {};
    }
};

export const loadMulipleWithStartEndTimeApi = async (deviceIds, startTime, endTime) => {
    let combineData = [];
    for (let d of deviceIds) {
        try {
            const res = await fetchDeviceDataWithStartEndTime(d.deviceId, startTime, endTime);
            combineData.push(res);
        } catch (err) {
            console.error(err);
        }
    }
    store.dispatch(loadMultipleData(combineData));
};
