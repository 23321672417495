import { resetState, scheduleType } from "../actionTypes";

export const schedules=(state=[],action)=>{
    const {type , payload} = action
    switch (type) {
        case scheduleType.FETCH:
            const filteredArray = [...state].filter(item1 =>
                !payload.some(item2 => item1._id === item2._id)
              );
            return [...filteredArray,...payload]
        case scheduleType.REMOVE:
            let sch=[...state]
            sch=sch.filter(id=>id._id!==payload)
            return sch
        case scheduleType.ENABLE:
            let enable=[]
            enable=[...state].map(id=>{
                if(id._id===payload.scheduleId){
                    return {...id,enabled:payload.enabled}
                }else{
                    return id
                }
            })
            return enable
            case scheduleType.UPDATE:
                let update=[]
                update=[...state].map(id=>{
                    if(id._id===payload._id){
                        return {...payload}
                    }else{
                        return id
                    }
                })
                return update
        case scheduleType.CREATE:
            return [...state,payload]
            case scheduleType.ALLREMOVE:
                return []
            case resetState.RESET:
                return []
        default:
            return state
    }
}