import React, { useEffect, useState } from "react";
import "./Schedule.css";
import {
  Container,
  Grid,
  Paper,
  Checkbox,
  IconButton,
  Typography,
  Box,
  Switch,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Drawer,
  TextField,
  InputLabel,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Fade,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  ArrowBack,
  Label,
  DeleteForever,
} from "@mui/icons-material";
import BreadcrumbsComponent from "../../Breadcrumb/Breadcrumb";
import { useOutletContext } from "react-router-dom";
import AddButton from "../../Common/AddButton";
import { useDispatch, useSelector } from "react-redux";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ScheduleDrawer from "./ScheduleDrawer";
import {
  activeDeactiveScheduleApi,
  convertTo12HourFormat,
  convertTo12HourMinuteFormat,
  cronExpressionToDays,
  cronToTimeString,
  deleteScheduleApi,
  fetchScheduleApi,
} from "../../../redux/action/schedule";
import { scheduleType } from "./data";
import moment from "moment";
import HeaderOperation from "../../HeaderOperations/HeaderOperation";
import ApplianceOverViewTable from "./ApplianceOverViewTable";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { fetchHouseDetails } from "../../../redux/action/house";
import {scheduleType as scheduleActionType} from '../../../redux/actionTypes'
import store from "../../../redux";
import { pages } from "../../../../ApiRoute";
import Loading from "../../../common/Loading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const devices = [
  {
    id: 1,
    serial: "01",
    name: "Evening Scene",
    time: "10:30 pm",
    days: "MTWTFSS",
    appliances: 3,
    status: "3 on 4 off",
    scheduleStatus: true,
    details: [
      { property: 1, room: "Bedroom", applianceName: "Fan", operation: "On" },
      { property: 1, room: "Bedroom", applianceName: "Light", operation: "On" },
      { property: 1, room: "Bedroom", applianceName: "AC", operation: "On" },
    ],
  },
  {
    id: 2,
    serial: "02",
    name: "Morning Scene",
    time: "7:00 am",
    days: "MTWTFSS",
    appliances: 2,
    status: "2 on 5 off",
    scheduleStatus: true,
    details: [
      {
        property: 2,
        room: "Living Room",
        applianceName: "Light",
        operation: "On",
      },
      {
        property: 2,
        room: "Living Room",
        applianceName: "Fan",
        operation: "Off",
      },
    ],
  },
  // Add more device entries as needed
];

const ScheduleOperation = () => {
  const houseData = useSelector((state) => state.houseData);
  const schedules = useSelector((state) => state.schedules);

  const housesList = useSelector((state) => state.housesList);
  const mutipleHouse = useSelector(state=>state.mutipleHouse)
  const dispatch = useDispatch()
  const [breadcrumbs] = useOutletContext();
  const [energiSync, setEnergiSync] = useState([]);
  const [selectedHouse, setSelectedHouse] = useState(housesList.length<10?housesList.map((e) => {
    return {
      value: e.houseAccessCode,
      label: e.houseName,
    };
  }):[]);
  const [loading,setLoading] = useState(false)
  const [expandedS, setExpandedS] = React.useState(false);

  const handleExpansion = () => {
    setExpandedS((prevExpanded) => !prevExpanded);
  };
  const headerStyle = { textAlign: "center", fontWeight: "bold" };
  const cellStyle = {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const [devicesState, setDevicesState] = useState(devices);
  const [expanded, setExpanded] = useState(null);
  const [editSchedule, setEditSchedule] = useState({});

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleOpen = (data) => {
    setEditSchedule(data);
    setDrawerOpen(true);
  };

  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id);
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };
  useEffect(()=>{
   if(schedules.length!==0 && selectedHouse.length===0){
    let ids= []
    for(let sc of schedules){
      if(!ids.includes(sc.house)){
        ids.push(sc.house)
      }
    }
    let data=[]
    for(let id of ids){
      if(mutipleHouse[id]===undefined) continue
      let obj = {
        value:id,
        label:mutipleHouse[id].houseName
      }
      data.push(obj)
    }
    setSelectedHouse(data)
   }
  },[schedules])
  useEffect(()=>{
    for (let house of selectedHouse) {
      if(mutipleHouse[house.value]===undefined){
        const loginDetails = store.getState().login;

        const mobileWithoutCountryCode =
          loginDetails?.profile?.mobile.slice(3);
       fetchHouseDetails(
         house.value,
         mobileWithoutCountryCode,
         pages.schedulePage
       );
      }
     }
  },[selectedHouse])
  useEffect(() => {
    let totalEnergy = [];
    
    if (Object.keys(mutipleHouse).length > 0) {
      for(let house of selectedHouse){
        let houseDataa = mutipleHouse[house.value]
        if(houseDataa===undefined) continue
      if (houseDataa.rooms.length > 0) {
        for (let room of houseDataa.rooms) {
          if (room.energiSync.length > 0) {
            for (let device of room.energiSync) {
              totalEnergy.push({ roomName: room.roomName,houseId:house.value,houseName:house.label, ...device });
            }
          }
        }
      }
      fetchScheduleApi(house.value);
    }
     
    }
    
    setEnergiSync(totalEnergy);
  }, [selectedHouse,mutipleHouse]);
  const findName = (id) => {
    
    let device = energiSync.find((ids) => ids.deviceId === id);

    if (device) {
      return `${
        device.name !== undefined && device.name !== ""
          ? device.name
          : device.deviceId
      }`;
    } else {
      return `${id}`;
    }
  };
  const findRoomName = (id) => {
    let device = energiSync.find((ids) => ids.deviceId === id);

    if (device) {
      return device?.roomName;
    } else {
      return "";
    }
  };
  const findStatus = (actions) => {
    let off = actions.filter((a) => a.payload.command === 0);
    let on = actions.filter((a) => a.payload.command !== 0);
    return `${on.length} on ${off.length} off`;
  };
    // Handler to toggle select all
    const handleChange = (selected) => {
      console.log(selected,"ese");
      let options =housesList.map((e) => {
        return {
          value: e.houseAccessCode,
          label: e.houseName,
        };
      })
      // Check if "Select All" option was clicked
      if (selected && selected.some((option) => option.value === "select_all")) {
        // Select All functionality
        setSelectedHouse(
          selected.length === options.length + 1 ? [] : [...options]
        );
      } else {
        setSelectedHouse(selected);
      }
    };
  return (
    <Box sx={{ backgroundColor: "rgb(245, 247, 250)", width: "100%" }}>
   {loading && <Loading/>}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "35px",
        }}
      >
        <HeaderOperation headerName={"Schedule Operation"} />
        <AddButton name="Add" fun={handleOpen} />
      </Box>
    {  
      housesList.length>=10 &&<Box
        sx={{
          // height: "100px",
          width: "100%",
          display: "flex",
          marginBottom:'50px',
          justifyContent: "space-between",
        }}
      >
        <Select
          className="react-select-container-house"
          classNamePrefix="react-select-house"
          style={{ width: "100% !important" }}
          closeMenuOnSelect={false}
          isMulti
          options={[{ value: "select_all", label: "Select All" },...housesList.map((e) => {
            return {
              value: e.houseAccessCode,
              label: e.houseName,
            };
          })]}
          value={selectedHouse}
          onChange={(data) => {
            if(data.length===0){
              dispatch({type:scheduleActionType.ALLREMOVE})
            }
           handleChange(data)
          }}
      
        />
        <Button
          style={{ height: "max-content" }}
          onClick={async() => {
            const loginDetails = store.getState().login;

            const mobileWithoutCountryCode =
              loginDetails.profile.mobile.slice(3);
              setLoading(true)
            for (let house of selectedHouse) {
             await fetchHouseDetails(
                house.value,
                mobileWithoutCountryCode,
                pages.schedulePage
              );
            }
            setLoading(false)
          }}
        >
          Get Schedule
        </Button>
      </Box>
    }
      <Container maxWidth="xl" style={{ padding: "0px" }}>
      <Accordion
        expanded={expandedS}
        onChange={handleExpansion}
        slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={[
          expandedS
            ? {
                "& .MuiAccordion-region": {
                  height: "auto",
                },
                "& .MuiAccordionDetails-root": {
                  display: "block",
                },
              }
            : {
                "& .MuiAccordion-region": {
                  height: 0,
                },
                "& .MuiAccordionDetails-root": {
                  display: "none",
                },
              },
        ]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Schedules</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor:"rgb(245, 247, 250)"}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              style={{
                padding: "16px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f8f9fa",
              }}
            >
              {/* <Box style={{ flex: 1, textAlign: 'center' }}>
                <Checkbox />
              </Box> */}
              <Typography variant="body1" style={{ ...headerStyle, flex: 1 }}>
                S. no.
              </Typography>
              <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>
                Name
              </Typography>
              <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>
                Time
              </Typography>
              <Typography variant="body1" style={{ ...headerStyle, flex: 1 }}>
                Days
              </Typography>
              <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>
                Appliances
              </Typography>
              <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>
                Status
              </Typography>
              <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>
                Schedule Status
              </Typography>
              <Typography variant="body1" style={{ ...headerStyle, flex: 1 }}>
                Action
              </Typography>
            </Paper>
          </Grid>
          {schedules.map((schedule, index) => (
            <Grid item xs={12} key={schedule._id}>
              <Paper
                style={{
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* <Box style={{ flex: 1, textAlign: 'center' }}>
                    <Checkbox />
                  </Box> */}
                  <Typography variant="body1" style={{ ...cellStyle, flex: 1 }}>
                    {index + 1}
                  </Typography>
                  <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>
                    {schedule.name}
                  </Typography>
                  <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>
                    {schedule.type === scheduleType.cron
                      ? cronToTimeString(schedule.expression)
                      : convertTo12HourMinuteFormat(
                          schedule.expression.split("T")[1]
                        )}
                  </Typography>
                  <Typography variant="body1" style={{ ...cellStyle, flex: 1 }}>
                    {schedule.type === scheduleType.cron
                      ? cronExpressionToDays(schedule.expression)
                      : schedule.expression.split("T")[0]}
                  </Typography>
                  <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>
                    {schedule.actions.length}
                  </Typography>
                  <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>
                    {findStatus(schedule.actions)}
                  </Typography>
                  <Box
                    style={{
                      ...cellStyle,
                      flex: 2,
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      checked={schedule.enabled}
                      onChange={(event) => {
                        activeDeactiveScheduleApi({
                          scheduleId: schedule._id,
                          enabled: event.target.checked,
                        });
                      }}
                    />
                  </Box>
                  <Box style={{ display: "flex", textAlign: "center" }}>
                    <IconButton onClick={() => deleteScheduleApi(schedule._id)}>
                      <DeleteForever />
                    </IconButton>

                    <IconButton onClick={() => handleExpandClick(schedule._id)}>
                      {expanded === schedule._id ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </Box>
                </Box>
                <Collapse
                  in={expanded === schedule._id}
                  timeout="auto"
                  unmountOnExit
                  style={{ width: "100%" }}
                >
                  <TableContainer
                    style={{
                      backgroundColor: "#F7F9FF",
                      borderRadius: "11.057px",
                      border: "0.691px solid #CED9FF",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            Property
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            Room
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            Appliance Name
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            Operation
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {schedule.actions.map((detail, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ textAlign: "center" }}>
                              {mutipleHouse[schedule.house]?.houseName}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {findRoomName(detail.payload.deviceId)}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {findName(detail.payload.deviceId)}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {detail.payload.command === 0 ? "OFF" : "ON"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                    className="mt-3"
                  >
                    <Box sx={{ width: "max-content" }}>
                      <AddButton name="Add" fun={handleOpen} data={schedule} />
                    </Box>
                  </Box>
                </Collapse>
              </Paper>
            </Grid>
          ))}
        </Grid>
        </AccordionDetails>
      </Accordion>
      </Container>
      <ApplianceOverViewTable
        schedules={schedules}

        
        houseName={houseData?.houseName}
        findName={findName}
      />
      <ScheduleDrawer
        handleCloseDrawer={handleCloseDrawer}
        drawerOpen={drawerOpen}
        energiSync={energiSync}
        id={houseData._id}
        editSchedule={editSchedule}
      />
    </Box>
  );
};

export default ScheduleOperation;
