// SettingsPage.js
import { Box, CssBaseline, Container } from '@mui/material';

import { Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import SettingsNavigation from './SettingsNavigation/SettingsNavigation';
import BreadcrumbsComponent from './Breadcrumb/Breadcrumb';
import GraphDataComponent from './GraphData/GraphData'; // Import the new GraphData component
import './SettingsPage.css'; // Import the CSS file
import SideBar from '../SideBar/SideBar';
import Header from '../Header/Header';
import {styles} from '../MainPage/MainPage'
const SettingsPage = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return (
    <div style={{ ...styles.container }}>
    {/* <SideBar /> */}
    <Box style={{ ...styles.rightContainer }}>
      <Header />
      <Box sx={{backgroundColor:'rgb(245, 247, 250)',height:'92vh',display:'flex',padding:'2%',overflowY:'scroll'}}>
      <CssBaseline />
      {/* <SettingsNavigation setBreadcrumbs={setBreadcrumbs} /> */}
      <Box component="main" sx={{ flexGrow: 1 }}>
      
          {/* <GraphDataComponent /> Use the GraphData component here */}
          
          {/* <BreadcrumbsComponent breadcrumbs={breadcrumbs}/> Add class name */}
          <Outlet context={[breadcrumbs]}/>
      
        </Box>
      </Box>
    </Box>
    </div>
  );
}

export default SettingsPage;
