// import moment from "moment";
// import { useEffect, useState } from "react"
// import ReactApexChart from "react-apexcharts";
// import ChartHeader from "../ChartHeader";
// import { images } from '../../common/images';

// export default function TemperatureLineChart({ selectedDeviceSelectedDayData }) {
//     const [series, setSeries] = useState([])
//     const [graphData, setGraphData] = useState([])
//     const [options, setOptions] = useState({
//         // colors:['#322FC8'],
//         chart: {
//             type: 'area',
//             stacked: false,
//             height: "215px",
//             zoom: {
//                 type: 'x',
//                 enabled: true,
//                 autoScaleYaxis: true
//             },
//             toolbar: {
//                 autoSelected: 'zoom'
//             }
//         },
//         dataLabels: {
//             enabled: false
//         },

//         markers: {
//             size: 0,
//         },
//         title: {
//             text: '',
//             align: 'left'
//         },

//         yaxis: {
//             labels: {
//                 formatter: function (val) {
//                     return (val).toFixed(0);
//                 },
//             },
//             title: {
//                 text: 'Celcius'
//             },
//         },
//         xaxis: {
//             type: 'datetime',
//             labels: {
//                 // format: 'yyyy-MM-dd HH:mm',
//                 formatter: function (value, timestamp) {
//                     return new Date(timestamp).toLocaleString('en-us', {

//                         // year: 'numeric',
//                         // month: 'long',
//                         // day: 'numeric',
//                         hour: 'numeric',
//                         minute: 'numeric',
//                         // second: 'numeric',
//                         // timeZoneName: 'short'

//                     }) // The formatter function overrides format property
//                 },
//             }
//         },
//         tooltip: {
//             shared: false,
//             x: {
//                 formatter: function (val) {
//                     return (new Date(val).toLocaleString('en-us', {

//                         // year: 'numeric',
//                         // month: 'long',
//                         // day: 'numeric',
//                         hour: 'numeric',
//                         minute: 'numeric',
//                     }))
//                 },
//             },
//             y: {
//                 formatter: function (val) {
//                     return (val)
//                 }
//             }
//         }
//     })

//     useEffect(() => {
//         if (graphData.length === 0) return
//         setSeries([{ name: `Temperature`, data: graphData }])
//         setOptions({
//             colors: ['#322FC8'],
//             chart: {
//                 type: 'area',
//                 stacked: false,
//                 height: "215px",
//                 zoom: {
//                     type: 'x',
//                     enabled: true,
//                     autoScaleXaxis: true
//                 },
//                 toolbar: {
//                     autoSelected: 'zoom'
//                 }
//             },
//             dataLabels: {
//                 enabled: false
//             },

//             markers: {
//                 size: 0,
//             },
//             title: {
//                 text: '',
//                 align: 'left'
//             },

//             yaxis: {
//                 labels: {
//                     formatter: function (val) {
//                         return (val).toFixed(0);
//                     },
//                 },
//                 title: {
//                     text: 'Celsius'
//                 },
//             },
//             xaxis: {
//                 type: 'datetime',
//                 labels: {
//                     // format: 'yyyy-MM-dd HH:mm',
//                     formatter: function (value, timestamp) {
//                         return new Date(timestamp).toLocaleString('en-us', {

//                             // year: 'numeric',
//                             // month: 'long',
//                             // day: 'numeric',
//                             hour: 'numeric',
//                             minute: 'numeric',
//                             // second: 'numeric',
//                             // timeZoneName: 'short'

//                         }) // The formatter function overrides format property
//                     },
//                 }
//             },
//             tooltip: {
//                 shared: false,
//                 x: {
//                     formatter: function (val) {
//                         return (new Date(val).toLocaleString('en-us', {

//                             // year: 'numeric',
//                             // month: 'long',
//                             // day: 'numeric',
//                             hour: 'numeric',
//                             minute: 'numeric',
//                         }))
//                     },
//                 },
//                 y: {
//                     formatter: function (val) {
//                         return (val)
//                     }
//                 }
//             }
//         })
//     }, [graphData])

//     useEffect(() => {
//         // let globalCarbonConsumedData = []

//         // const sortedLoadLogs = loadLogs.sort((a, b) => parseFloat(a.ts) - parseFloat(b.ts));

//         if (selectedDeviceSelectedDayData == undefined || selectedDeviceSelectedDayData.length === 0) return

//         let sortedLoadLogs
//         for (let data of selectedDeviceSelectedDayData) {
//             if (Object.keys(data).length === 0) continue
//             sortedLoadLogs = data.temperatureLogs.sort(
//                 (a, b) => parseFloat(a.ts) - parseFloat(b.ts)
//             );

//         }

//         let data = []
//         for (let i of sortedLoadLogs) {
//             if (i.t > 0) {
//                 let d = [i.ts, i.t];
//                 data.push(d);
//             }
//         }

//         console.log(data,'data')
//         setGraphData(data)

//     }, [selectedDeviceSelectedDayData])

//     return (
//         <div>
//             <ChartHeader
//                 name={`Temperature`}
//                 // subName={`${(selectedDeviceSelectedDayData.length === 1 && Object.keys(selectedDeviceSelectedDayData[0]).length !== 0 && selectedDeviceSelectedDayData[0]?.temperatureLogs?.length !== 0)
//                 //     ? selectedDeviceSelectedDayData[0].temperatureLogs[
//                 //         selectedDeviceSelectedDayData[0].temperatureLogs.length - 1
//                 //     ].t.toFixed(2)
//                 //     : 0
//                 //     }°C`}

//                 // subName={`${selectedDeviceSelectedDayData?.[0]?.temperatureLogs?.slice(-1)?.[0]?.t.toFixed(2) || 0}°C`}
//                 image={images.temperature}
//                 textColor={"#322FC8"}
//                 iconBackgroundColor={"rgba(50, 47, 200, 0.10)"}
//             />
//             <ReactApexChart options={options} series={series} type="line" height={215} />

//         </div>
//     );
// }

import moment from "moment";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ChartHeader from "../ChartHeader";
import { images } from "../../common/images";

export default function WeatherControllog({
  selectedDeviceSelectedDayData,name,dataKey,valueKey,timeKey,toolTip,yAxis
}) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    colors: ["#322FC8"],
    chart: {
      type: "area",
      stacked: false,
      height: "215px",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
        export: {
          csv: {
            categoryFormatter(x) {
              return new Date(x)
                .toLocaleString("en-IN", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })
                .replace(",", "");
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "",
      align: "left",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text:yAxis,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (value, timestamp) {
          return new Date(timestamp).toLocaleString("en-us", {
            hour: "numeric",
            minute: "numeric",
          });
        },
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return new Date(val).toLocaleString("en-us", {
            hour: "numeric",
            minute: "numeric",
          });
        },
      },
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  });

  useEffect(() => {
    if (
      selectedDeviceSelectedDayData &&
      selectedDeviceSelectedDayData.length > 0
    ) {
      let sortedLoadLogs = [];
      for (let data of selectedDeviceSelectedDayData) {
        if (Object.keys(data).length === 0) continue;
        sortedLoadLogs =data[dataKey]!==undefined? data[dataKey].sort(
          (a, b) => parseFloat(a[timeKey]) - parseFloat(b[timeKey])
        ):[];
      }

      let data = [];
      console.log('====================================');
      console.log(sortedLoadLogs,"lohhh");
      console.log('====================================');
      for (let i of sortedLoadLogs) {
        if (i[valueKey] > 0) {
          let d = [i[timeKey], i[valueKey]];
          data.push(d);
        }
      }
console.log('====================================');
console.log(data,"lohhh");
console.log('====================================');
      console.log(data, "data");
      setSeries([{ name: toolTip, data }]);
    } else {
      setSeries([]); // Clear the series when data is empty
    }
  }, [selectedDeviceSelectedDayData]);

  return (
    <div>
      <ChartHeader
        name={name}
        image={images.temperature}
        textColor={"#322FC8"}
        iconBackgroundColor={"rgba(50, 47, 200, 0.10)"}
      />
      {series.length > 0 ? (
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={215}
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}
