
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function UnitSavedDetailContainer({ time, unitsSaved }) {

  return (
    <DetailContainer
      name={"Total Units Saved"}
      value={`${unitsSaved.toFixed(2)} kWh`}
      time={time}
      color={"rgba(50, 47, 200, 0.10)"}
      borderColor={"#322FC8"}
      image={images.unit !== undefined ? images.unit : 0}
    />
  )
}

export default UnitSavedDetailContainer