import React from 'react';
import { Container, Grid, Paper, Checkbox, IconButton, Typography, Box } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const devices = [
  { id: 1, serial: '01', sync: 'M020268', property: 'Bukhara', room: 'Living room', wifiName: 'Aliste Automation', password: '913*******', status: 'Device has received the credentials' },
  { id: 2, serial: '01', sync: 'M020268', property: 'Bukhara', room: 'Living room', wifiName: 'Aliste Automation', password: '913*******', status: 'Device has received the credentials' },
  { id: 3, serial: '01', sync: 'M020268', property: 'Bukhara', room: 'Living room', wifiName: 'Aliste Automation', password: '913*******', status: 'Device has received the credentials' },
  { id: 4, serial: '01', sync: 'M020268', property: 'Bukhara', room: 'Living room', wifiName: 'Aliste Automation', password: '913*******', status: 'Device has received the credentials' },
  { id: 5, serial: '01', sync: 'M020268', property: 'Bukhara', room: 'Living room', wifiName: 'Aliste Automation', password: '913*******', status: 'Device has received the credentials' },
];

const EditName = () => {
  const headerStyle = { textAlign: 'center', fontWeight: 'bold' };
  const cellStyle = { textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' };

  return (
    <Container maxWidth="xl" style={{ padding: '0px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: '16px', display: 'flex', alignItems: 'center', backgroundColor: '#f8f9fa' }}>
            <Box style={{ flex: 0.5 }}></Box>
            <Typography variant="body1" style={{ ...headerStyle, flex: 0.5 }}>S. No.</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 1 }}>Sync</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Property</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Room</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Wifi Name</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Password</Typography>
            <Typography variant="body1" style={{ ...headerStyle, flex: 2 }}>Device Status</Typography>
            <Box style={{ flex: 1 }}></Box>
          </Paper>
        </Grid>
        {devices.map((device) => (
          <Grid item xs={12} key={device.id}>
            <Paper style={{ padding: '16px', display: 'flex', alignItems: 'center' }}>
              <Box style={{ flex: 0.5, padding: 0 }}>
                <Checkbox />
              </Box>
              <Typography variant="body1" style={{ ...cellStyle, flex: 0.5 }}>{device.serial}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 1 }}>{device.sync}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.property}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.room}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.wifiName}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.password}</Typography>
              <Typography variant="body1" style={{ ...cellStyle, flex: 2 }}>{device.status}</Typography>
              <Box style={{ flex: 1 }}>
                <IconButton><MoreVert /></IconButton>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EditName;
