import { Box } from '@mui/material'
import React from 'react'
import './AddButton.css'

function AddButton({ name,fun,data={} }) {
    return (
        <Box 
          className="addButtonContainer"
          onClick={()=>fun(data)}
        >
            <Box >{name}</Box>
            <Box className="addButtonIconCoontainer">+</Box>
        </Box>
    )
}

export default AddButton