import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ChartHeader from "../ChartHeader";
import { images } from "../../common/images";

export default function PowerConsumptionLineChart({
  selectedDeviceSelectedDayData,
  deviceId,
}) {
  const [series, setSeries] = useState([
    { name: "Power Consumption", data: [] },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: "area",
      stacked: false,
      height: "215px",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
        export: {
          csv: {
            categoryFormatter(x) {
              return new Date(x).toLocaleString('en-IN', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(',', '');
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "",
      align: "left",
    },
    yaxis: {
      labels: {
        formatter: (val) => val.toFixed(0),
      },
      title: {
        text: "Wattage",
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (val) =>
          new Date(val).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
          }),
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: (val) =>
          new Date(val).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
          }),
      },
      y: {
        formatter: (val) => val.toFixed(2),
      },
    },
  });

  // useEffect(() => {
  //     if (!selectedDeviceSelectedDayData || selectedDeviceSelectedDayData.length === 0) return;

  //     const loadLogs = selectedDeviceSelectedDayData.flatMap(data => data.loadLogs).filter(log => log.p > 0);
  //     const sortedLoadLogs = loadLogs.sort((a, b) => parseFloat(a.ts) - parseFloat(b.ts));

  //     const seriesData = sortedLoadLogs.map(log => [log.ts, log.p]);
  //     setOptions(prevOptions => ({
  //         ...prevOptions,
  //         colors: ['#322FC8']
  //     }));
  //     setSeries([{ name: 'Wattage', data: seriesData }]);
  // }, [selectedDeviceSelectedDayData]);

  useEffect(() => {
    if (
      selectedDeviceSelectedDayData &&
      selectedDeviceSelectedDayData.length > 0
    ) {
      let sortedLoadLogs = [];
      for (let data of selectedDeviceSelectedDayData) {
        if (Object.keys(data).length === 0) continue;
        sortedLoadLogs =data?.loadLogs!==undefined? data?.loadLogs?.sort(
          (a, b) => parseFloat(a.ts) - parseFloat(b.ts)
        ):[]
      }

      console.log(sortedLoadLogs, "sortedLoadLogs");

      let sortAccordingM = {
        0: [],
      };
      for (let p of sortedLoadLogs) {
        if (p.m === undefined || p.m === 0) {
          sortAccordingM["0"].push(p);
        } else {
          if (sortAccordingM[`${p.m}`] === undefined) {
            sortAccordingM[`${p.m}`] = [];
          }
          sortAccordingM[`${p.m}`].push(p);
        }
      }

      console.log(sortAccordingM, "sortAccordingM");

      let data = [];

      for (let i of Object.keys(sortAccordingM)) {
        let power = [];
        for (let j of sortAccordingM[i]) {
          let data = [j.ts, j.p];
          power.push(data);
        }

        data.push({
          data: power,
          name: `${deviceId}-Phase ${Number(i) + 1}`,
        });
      }

      console.log(data, "data");
      setSeries(data);
    } else {
      setSeries([]); // Clear the series when data is empty
    }
  }, [selectedDeviceSelectedDayData]);

  return (
    <div>
      <ChartHeader
        name="Power Consumption"
        // subName={`${selectedDeviceSelectedDayData?.[0]?.loadLogs?.slice(-1)?.[0]?.p.toFixed(2) || 0} W`}
        image={images.temperature}
        textColor="#322FC8"
        iconBackgroundColor="rgba(50, 47, 200, 0.10)"
      />
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={215}
      />
    </div>
  );
}
