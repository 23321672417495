import store from "..";
import axios from "axios"
import { houseData, housesList, resetState } from "../actionTypes"
import { housesListAction } from "./housesList";
import { fetchScheduleApi, loadSchedule } from "./schedule";
import { pages } from "../../../ApiRoute";
import { loadHouse } from "./CollectiveHouse";

export const houseDataAction = (data) => {
    return {
        type: houseData.LOADHOUSE,
        payload: data
    }
}


export const fetchHouseDetails = async (houseId, phoneNumber,page="") => {

    try {
        const response = await axios.get(
            `https://subscriptioncloud.alistetechnologies.com/api/fetch/house2/${houseId}/+91${phoneNumber}?user=+91${[phoneNumber]}`,
        );
        if (response.status === 200) {
          if(page===""){
            store.dispatch(houseDataAction(response.data));
            store.dispatch(loadHouse(houseId,response.data))
          }else if(page===pages.schedulePage){
            store.dispatch(loadHouse(houseId,response.data))
          }
            // fetchScheduleApi(houseId)
            // // store.dispatch({type:resetState.RESET})
            return { success: true, message: 'Sign in successful' ,data:response.data};
        } else {
            return { success: false, message: 'Authentication Error' };
        }
    } catch (error) {
        let message = error.message;

        if (error.response && error.response.data) {
            message = error.response.data.message;
        }

        return { success: false, message };
    }
}

export const fetchAllHouses = async (phoneNumber) => {

    try {
        const response = await axios.get(
            `https://subscriptioncloud.alistetechnologies.com/api/fetch/keys/+91${phoneNumber}?user=+91${[phoneNumber]}`,
        );

        let combinedArray = []

        if (response.status === 200) {

            const allHousesList = response.data

            // Loop over each property in the input object
            for (const key in allHousesList) {
                if (Array.isArray(allHousesList[key])) {
                    combinedArray.push(...allHousesList[key]);
                }
            }

            store.dispatch(housesListAction(combinedArray))

            return { success: true, message: 'Sign in successful', data: response.data };

        } else {
            return { success: false, message: 'Authentication Error' };
        }
    } catch (error) {
        let message = error.message;

        if (error.response && error.response.data) {
            message = error.response.data.message;
        }

        return { success: false, message };
    }
}

export const updateDefaultHouse = async (houseId, phoneNumber) => {

    try {

        console.log(houseId, 'houseId')
        console.log(phoneNumber, 'phoneNumber')

        const response = await axios.post(
            `https://subscriptioncloud.alistetechnologies.com/api/update/selectedHouse?user=+91${phoneNumber}`,
            {
                email: `+91${phoneNumber}`,
                houseAccessCode: houseId,
            },
        );

    } catch (error) {
        let message = error.message;

        if (error.response && error.response.data) {
            message = error.response.data.message;
        }

        return { success: false, message };
    }

}