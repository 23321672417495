import React, { useEffect, useRef, useState } from "react";
import { CaretDownFill } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loginDetail } from "../redux/action/login";
import { clearDeviceDropdownData } from "../redux/action/deviceDropdownSelection";
import logo from "../../assets/logo.png";
import { images, octiotUrl } from "../common/images";
import { Toaster } from "react-hot-toast";
import "./Header.css";
import LogOut from "./LogOut";

function Header() {
  const [pop, setPop] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  useEffect(() => {
    if (Object.keys(login).length === 0) navigate("/");
  }, [login]);
  const ref = useRef();
  const style = {
    headerContainer: {
      height: "8vh",
      width: "100%",
      padding: "0 2%",
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    welcome: {
      color: "#212529",
      fontFamily: "manrope",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },
    login: {
      color: "#000",
      fontFamily: "manrope",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      marginRight: "8px",
    },
  };

  const styleAll = {
    width: document.URL.includes(octiotUrl) ? "70px" : "40.304px",
  };
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setPop(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  useEffect(() => {
    console.log(document.URL);
    const faviconUpdate = async () => {
      //grab favicon element by ID
      const favicon = document.getElementById("favicon");

      //check count value, if below 0 we change href property to our red circle image path
      if (document.URL.includes(octiotUrl)) {
        favicon.href = `/OCTIOT.ico`;
        document.title = "Energy Savings";
      }
      //if above 0, we set back to green
      else {
        favicon.href = `/favicon.ico`;
      }
    };
    //run our function here
    faviconUpdate();
  }, []);

  return (
    <div style={{ ...style.headerContainer }}>
      <Toaster />
      <div className="d-flex gap-5 align-items-center">
        <div style={{ display: "flex" }}>
          <img
            src={
              document.URL.includes(octiotUrl)
                ? images.octiot
                : images.loginLogo
            }
            style={{
              width: document.URL.includes(octiotUrl) ? "6.5vw" : "10vw",
            }}
          />
        </div>
        <div className="d-flex gap-4">
          <div
            className={document.URL.includes('/dashboard') ? 'tabNameActive' : 'tabName'}
            onClick={() => {
              navigate(`/dashboard`);
            }}
          >
            Home
          </div>
          <div
            className={document.URL.includes('/schedules') ? 'tabNameActive' : 'tabName'}
            onClick={() => {
              navigate(`/schedules`);
            }}
          >
            Schedules
          </div>
          <div
            className={document.URL.includes('/reports') ? 'tabNameActive' : 'tabName'}
            onClick={() => {
              navigate(`/reports`);
            }}
          >
            Reports
          </div>
          {/* <div
            className={document.URL.includes('/deviceStaus') ? 'tabNameActive' : 'tabName'}
            onClick={() => {
              navigate(`/deviceStaus`);
            }}
          >
            Device Status
          </div> */}
          {/* <div
            className={document.URL.includes('/summary') ? 'tabNameActive' : 'tabName'}
            onClick={() => {
              navigate(`/summary`);
            }}
          >
            Summary
          </div> */}
        </div>
      </div>

      <div className="d-flex gap-5">
        <div className="userName">{login?.profile?.first_name}</div>
        <LogOut />
      </div>
    </div>
  );
}

export default Header;
